import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Menu from "./Menu";
import "./App.css";
import Footer from "./Footer";
import ReactApexChart from "react-apexcharts";
import CollapsibleTab from "./CollapsibleTab";

const links = [
  {
    href: "https://www.lipidjournal.com/article/S1933-2874(24)00240-X/fulltext",
    text: "Role of apolipoprotein B in the clinical management of cardiovascular risk in adults",
  },
];

const LDLApoB = () => {
  const [isToggled, setIsToggled] = useState(true);
  const [ldlc, setLdlc] = useState("");
  const [apob, setApob] = useState("");
  const [series, setSeries] = useState([]);
  const [comparisonColor, setComparisonColor] = useState("#4CAF50cc"); // Default to green with opacity
  const [discordance, setDiscordance] = useState(null);
  const [showMenu, setShowMenu] = useState(window.innerWidth > 768);

  const [chartOptions, setChartOptions] = useState({
    chart: {
      type: "line",
      height: 350,
      toolbar: {
        show: false,
      },
      fontFamily: "Poppins, sans-serif",
    },
    xaxis: {
      title: {
        text: "LDL-C (mg/dL)",
        style: {
          fontSize: "14px",
          fontFamily: "Poppins, sans-serif",
        },
      },
      max: 200,
      min: 0,
      tickAmount: 9,
      labels: {
        formatter: function (val) {
          return Math.round(val);
        },
        style: {
          fontFamily: "Poppins, sans-serif",
        },
      },
    },
    yaxis: {
      title: {
        text: "ApoB (mg/dL)",
        style: {
          fontSize: "14px",
          fontFamily: "Poppins, sans-serif",
        },
      },
      max: 200,
      tickAmount: 10,
      labels: {
        formatter: function (val) {
          return Math.round(val);
        },
        style: {
          fontFamily: "Poppins, sans-serif",
        },
      },
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    stroke: {
      curve: "straight",
      width: [1, 2, 2, 2],
      colors: ["transparent", "#000000", "#939393cc", "#4CAF50cc"],
    },
    colors: ["#ff9933", "#000000", "#939393cc", "#4CAF50cc"],
    fill: {
      type: ["solid", "none", "solid", "solid"],
      opacity: [0.3, 1, 1, 1],
    },
    markers: {
      size: [0, 0, 10, 10],
      colors: ["transparent", "#000000", "#939393cc", "#4CAF50cc"],
      strokeWidth: [0, 2, 2, 2],
      strokeColors: ["transparent", "#000000", "#939393cc", "#4CAF50cc"],
    },
    annotations: {
      points: [
        // Predicted point annotation
        ...(ldlc && !isNaN(ldlc)
          ? [
              {
                x: parseFloat(ldlc),
                y: isToggled
                  ? 17.3 + 0.652 * parseFloat(ldlc)
                  : 23.7 + 0.699 * parseFloat(ldlc),
                marker: {
                  size: 0,
                },
                label: {
                  borderColor: "#939393",
                  style: {
                    color: "#fff",
                    background: "#939393",
                    fontFamily: "Poppins, sans-serif",
                  },
                  text: `Predicted ApoB: ${Math.round(
                    isToggled
                      ? 17.3 + 0.652 * parseFloat(ldlc)
                      : 23.7 + 0.699 * parseFloat(ldlc)
                  )}`,
                  offsetY: -15,
                },
              },
            ]
          : []),
        // Actual point annotation
        ...(ldlc && apob && !isNaN(ldlc) && !isNaN(apob)
          ? [
              {
                x: parseFloat(ldlc),
                y: parseFloat(apob),
                marker: {
                  size: 0,
                },
                label: {
                  borderColor: "#4CAF50",
                  style: {
                    color: "#fff",
                    background: "#4CAF50",
                    fontFamily: "Poppins, sans-serif",
                  },
                  text: `Actual ApoB: ${Math.round(parseFloat(apob))}`,
                  offsetY: -15,
                },
              },
            ]
          : []),
      ],
    },
    tooltip: {
      enabled: true,
      shared: false,
      intersect: true,
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        const x = w.globals.seriesX[seriesIndex]?.[dataPointIndex];
        const value = series[seriesIndex]?.[dataPointIndex];

        if (!x || !value) return "";

        if (seriesIndex <= 2) {
          // Area series (risk regions)
          return "";
        } else if (seriesIndex === 3) {
          // Line series
          return (
            '<div class="custom-tooltip" style="font-family: Poppins, sans-serif;">' +
            "LDL-C: " +
            Math.round(x) +
            "<br/>" +
            "Predicted ApoB: " +
            Math.round(value) +
            "</div>"
          );
        } else if (seriesIndex === 4) {
          // Predicted point
          return (
            '<div class="custom-tooltip" style="font-family: Poppins, sans-serif;">' +
            "LDL-C: " +
            Math.round(x) +
            "<br/>" +
            "Predicted ApoB: " +
            Math.round(value) +
            "</div>"
          );
        } else if (seriesIndex === 5) {
          // Actual point
          return (
            '<div class="custom-tooltip" style="font-family: Poppins, sans-serif;">' +
            "LDL-C: " +
            Math.round(x) +
            "<br/>" +
            "Actual ApoB: " +
            Math.round(value) +
            "</div>"
          );
        }
        return "";
      },
    },
    legend: {
      show: false,
    },
  });

  useEffect(() => {
    const handleResize = () => {
      setShowMenu(window.innerWidth > 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    // Generate line points for very high risk region (x <= 55)
    const veryHighRisk = [];
    for (let x = 0; x <= 55; x += 1) {
      const y = isToggled ? 23.7 + 0.699 * x : 17.3 + 0.652 * x;
      veryHighRisk.push({
        x: x,
        y: y,
      });
    }

    // Generate line points for high risk region (55 < x <= 70)
    const highRisk = [];
    for (let x = 55; x <= 70; x += 1) {
      const y = isToggled ? 23.7 + 0.699 * x : 17.3 + 0.652 * x;
      highRisk.push({
        x: x,
        y: y,
      });
    }

    // Generate line points for borderline risk region (70 < x <= 100)
    const borderlineRisk = [];
    for (let x = 70; x <= 100; x += 1) {
      const y = isToggled ? 23.7 + 0.699 * x : 17.3 + 0.652 * x;
      borderlineRisk.push({
        x: x,
        y: y,
      });
    }

    // Generate line points for the entire range (continuous black line)
    const allPoints = [];
    for (let x = 0; x <= 200; x += 1) {
      const y = isToggled ? 23.7 + 0.699 * x : 17.3 + 0.652 * x;
      allPoints.push({
        x: x,
        y: y,
      });
    }

    // Calculate predicted ApoB if LDL-C is entered
    let predictedPoint = [];
    let predictedApoB = 0;
    let predictedLDLC = 0;

    if (ldlc && !isNaN(ldlc)) {
      predictedApoB = isToggled
        ? 23.7 + 0.699 * parseFloat(ldlc)
        : 17.3 + 0.652 * parseFloat(ldlc);

      predictedPoint = [
        {
          x: parseFloat(ldlc),
          y: predictedApoB,
        },
      ];
    }

    // Calculate predicted LDL-C if only ApoB is entered
    if (apob && !isNaN(apob) && (!ldlc || isNaN(ldlc))) {
      predictedLDLC = isToggled
        ? (parseFloat(apob) - 23.7) / 0.699
        : (parseFloat(apob) - 17.3) / 0.652;
    }

    // Determine color based on comparison
    const actualApoB = parseFloat(apob);
    const newComparisonColor =
      (ldlc && actualApoB > predictedApoB ? "#B85C5C" : "#5B7DB1") + "cc";
    setComparisonColor(newComparisonColor);

    // Add actual point if either LDL-C and ApoB are entered, or just ApoB
    let actualPoint = [];
    if (apob && !isNaN(apob)) {
      if (ldlc && !isNaN(ldlc)) {
        // Both values entered
        actualPoint = [
          {
            x: parseFloat(ldlc),
            y: actualApoB,
          },
        ];
      } else {
        // Only ApoB entered
        actualPoint = [
          {
            x: predictedLDLC,
            y: actualApoB,
          },
        ];
      }
    }

    // Calculate y-values for reference lines
    const referenceX1 = 55;
    const referenceY1 = isToggled
      ? 23.7 + 0.699 * referenceX1
      : 17.3 + 0.652 * referenceX1;

    const referenceX2 = 70;
    const referenceY2 = isToggled
      ? 23.7 + 0.699 * referenceX2
      : 17.3 + 0.652 * referenceX2;

    const referenceX3 = 100;
    const referenceY3 = isToggled
      ? 23.7 + 0.699 * referenceX3
      : 17.3 + 0.652 * referenceX3;

    // Create reference line data
    const referenceLine1 = [
      { x: 0, y: referenceY1 },
      { x: referenceX1, y: referenceY1 },
    ];

    const referenceLine2 = [
      { x: 0, y: referenceY2 },
      { x: referenceX2, y: referenceY2 },
    ];

    const referenceLine3 = [
      { x: 0, y: referenceY3 },
      { x: referenceX3, y: referenceY3 },
    ];

    setSeries([
      {
        name: "Very High Risk Region",
        type: "area",
        data: veryHighRisk,
      },
      {
        name: "High Risk Region",
        type: "area",
        data: highRisk,
      },
      {
        name: "Borderline Risk Region",
        type: "area",
        data: borderlineRisk,
      },
      {
        name: "Predicted LDL-C, ApoB Relationship",
        type: "line",
        data: allPoints,
      },
      {
        name: "Predicted Value",
        type: "scatter",
        data: predictedPoint,
      },
      {
        name: "Actual Value",
        type: "scatter",
        data: actualPoint,
      },
      {
        name: "Reference Line 1",
        type: "line",
        data: referenceLine1,
      },
      {
        name: "Reference Line 2",
        type: "line",
        data: referenceLine2,
      },
      {
        name: "Reference Line 3",
        type: "line",
        data: referenceLine3,
      },
    ]);

    setChartOptions((prevOptions) => ({
      ...prevOptions,
      stroke: {
        ...prevOptions.stroke,
        width: [1, 1, 1, 2, 2, 2, 3, 3, 3],
        colors: [
          "transparent",
          "transparent",
          "transparent",
          "#000000",
          "#939393cc",
          newComparisonColor,
          "rgba(136, 184, 136, 0.8)",
          "rgba(179, 230, 161, 0.6)",
          "rgba(255, 223, 102, 0.9)",
        ],
        dashArray: [0, 0, 0, 0, 0, 0, 5, 5, 5],
      },
      colors: [
        "rgba(136, 184, 136, 0.5)",
        "rgba(179, 230, 161, 0.5)",
        "rgba(255, 255, 204, 0.5)",
        "#000000",
        "#939393cc",
        newComparisonColor,
        "rgba(136, 184, 136, 0.8)",
        "rgba(179, 230, 161, 0.6)",
        "rgba(255, 223, 102, 0.9)",
      ],
      fill: {
        type: [
          "solid",
          "solid",
          "solid",
          "none",
          "solid",
          "solid",
          "none",
          "none",
          "none",
        ],
        opacity: [0.8, 0.8, 0.8, 1, 1, 1, 1, 1, 1],
      },
      markers: {
        ...prevOptions.markers,
        size: [0, 0, 0, 0, 10, 10, 0, 0, 0],
        colors: [
          "transparent",
          "transparent",
          "transparent",
          "#000000",
          "#939393cc",
          newComparisonColor,
          "rgba(136, 184, 136, 0.8)",
          "#808080",
          "#808080",
        ],
        strokeWidth: [0, 0, 0, 2, 2, 2, 0, 0, 0],
        strokeColors: [
          "transparent",
          "transparent",
          "transparent",
          "#000000",
          "#939393cc",
          newComparisonColor,
          "rgba(136, 184, 136, 0.8)",
          "#808080",
          "#808080",
        ],
      },
      annotations: {
        points: [
          // Predicted point annotation
          ...(ldlc && !isNaN(ldlc)
            ? [
                {
                  x: parseFloat(ldlc),
                  y: predictedApoB,
                  marker: { size: 0 },
                  label: {
                    borderColor: "#939393cc",
                    style: {
                      color: "#fff",
                      background: "#939393cc",
                      fontFamily: "Poppins, sans-serif",
                    },
                    text: `Predicted ApoB: ${Math.round(predictedApoB)}`,
                    offsetY: -15,
                  },
                },
              ]
            : []),
          // Actual point annotation
          ...(apob && !isNaN(apob)
            ? [
                {
                  x: ldlc && !isNaN(ldlc) ? parseFloat(ldlc) : predictedLDLC,
                  y: actualApoB,
                  marker: { size: 0 },
                  label: {
                    borderColor: newComparisonColor,
                    style: {
                      color: "#fff",
                      background: newComparisonColor,
                      fontFamily: "Poppins, sans-serif",
                    },
                    text:
                      ldlc && !isNaN(ldlc)
                        ? `Actual ApoB: ${Math.round(actualApoB)}`
                        : `Predicted LDL-C: ${Math.round(predictedLDLC)}`,
                    offsetY:
                      actualApoB <= predictedApoB
                        ? 35
                        : actualApoB > predictedApoB &&
                          actualApoB < predictedApoB + 11
                        ? -30
                        : -15,
                  },
                },
              ]
            : []),
        ],
      },
      tooltip: {
        ...prevOptions.tooltip,
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          const x = w.globals.seriesX[seriesIndex]?.[dataPointIndex];
          const value = series[seriesIndex]?.[dataPointIndex];

          if (!x || !value) return "";

          if (seriesIndex <= 2) {
            // Area series (risk regions)
            return "";
          } else if (seriesIndex === 3) {
            // Line series
            return (
              '<div class="custom-tooltip" style="font-family: Poppins, sans-serif;">' +
              "LDL-C: " +
              Math.round(x) +
              "<br/>" +
              "Predicted ApoB: " +
              Math.round(value) +
              "</div>"
            );
          } else if (seriesIndex === 4) {
            // Predicted point
            return (
              '<div class="custom-tooltip" style="font-family: Poppins, sans-serif;">' +
              "LDL-C: " +
              Math.round(x) +
              "<br/>" +
              "Predicted ApoB: " +
              Math.round(value) +
              "</div>"
            );
          } else if (seriesIndex === 5) {
            // Actual point
            if (ldlc && !isNaN(ldlc)) {
              return (
                '<div class="custom-tooltip" style="font-family: Poppins, sans-serif;">' +
                "LDL-C: " +
                Math.round(x) +
                "<br/>" +
                "Actual ApoB: " +
                Math.round(value) +
                "</div>"
              );
            } else {
              return (
                '<div class="custom-tooltip" style="font-family: Poppins, sans-serif;">' +
                "Predicted LDL-C: " +
                Math.round(x) +
                "<br/>" +
                "ApoB: " +
                Math.round(value) +
                "</div>"
              );
            }
          }
          return "";
        },
      },
    }));

    // Calculate discordance when both values are present
    if (ldlc && apob && !isNaN(ldlc) && !isNaN(apob)) {
      const predictedApoB = isToggled
        ? 23.7 + 0.699 * parseFloat(ldlc)
        : 17.3 + 0.652 * parseFloat(ldlc);
      const actualApoB = parseFloat(apob);
      const percentDiff = ((actualApoB - predictedApoB) / predictedApoB) * 100;
      setDiscordance(percentDiff);
    } else {
      setDiscordance(null);
    }
  }, [isToggled, ldlc, apob]);

  return (
    <div className="page_div" data-component="ldl-apob">
      {showMenu && (
        <div className="menu_div">
          <Menu />
        </div>
      )}

      <div className="content_div">
        <h1 className="title">LDL-C, ApoB Comparator</h1>

        <div className="calculator-container" style={{ maxWidth: "90%" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "20px",
              marginBottom: "20px",
              width: "100%",
              maxWidth: "900px",
              margin: "0 auto 20px auto",
              flexDirection: window.innerWidth <= 768 ? "column" : "row",
              padding: "0 15px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                flex: 1,
                flexDirection: window.innerWidth <= 768 ? "column" : "row",
              }}
            >
              <label
                htmlFor="ldlc"
                className="form-label"
                style={{
                  margin: 0,
                  lineHeight: "1.2",
                  fontSize: "clamp(0.8rem, 2vw, 1rem)",
                  width: window.innerWidth <= 768 ? "100%" : "auto",
                  textAlign: window.innerWidth <= 768 ? "left" : "right",
                }}
              >
                LDL-C
              </label>
              <input
                type="number"
                id="ldlc"
                className="form-control"
                value={ldlc}
                onChange={(e) => setLdlc(e.target.value)}
                placeholder="mg/dL"
                style={{
                  margin: 0,
                  flex: 1,
                  width: window.innerWidth <= 768 ? "100%" : "auto",
                  fontSize: "clamp(0.8rem, 2vw, 1rem)",
                }}
              />
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                flex: 1,
                flexDirection: window.innerWidth <= 768 ? "column" : "row",
              }}
            >
              <label
                htmlFor="apob"
                className="form-label"
                style={{
                  margin: 0,
                  lineHeight: "1.2",
                  fontSize: "clamp(0.8rem, 2vw, 1rem)",
                  width: window.innerWidth <= 768 ? "100%" : "auto",
                  textAlign: window.innerWidth <= 768 ? "left" : "right",
                }}
              >
                ApoB
              </label>
              <input
                type="number"
                id="apob"
                className="form-control"
                value={apob}
                onChange={(e) => setApob(e.target.value)}
                placeholder="mg/dL"
                style={{
                  margin: 0,
                  flex: 1,
                  width: window.innerWidth <= 768 ? "100%" : "auto",
                  fontSize: "clamp(0.8rem, 2vw, 1rem)",
                }}
              />
            </div>
          </div>

          <div className="chart-container">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginBottom: "20px",
              }}
            >
              <label
                style={{
                  marginBottom: "10px",
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                On lipid lowering therapies?
              </label>
              <label className="toggle-button-cover">
                <div id="button-2" className="button r">
                  <input
                    className="checkbox"
                    type="checkbox"
                    checked={isToggled}
                    onChange={(e) => setIsToggled(e.target.checked)}
                  />
                  <div className="knobs"></div>
                  <div className="layer"></div>
                </div>
              </label>
            </div>

            <ReactApexChart
              options={chartOptions}
              series={series}
              type="line"
              height={400}
              width="100%"
            />

            <div
              className="mt-4"
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <div className="legend-container">
                <div className="legend">
                  <div className="legend-item">
                    <div
                      className="legend-color"
                      style={{
                        backgroundColor: "rgba(136, 184, 136, 0.8)",
                        opacity: 0.8,
                        border: "1px solid black",
                      }}
                    ></div>
                    <span className="legend-label">Very high risk target</span>
                  </div>
                  <div className="legend-item">
                    <div
                      className="legend-color"
                      style={{
                        backgroundColor: "rgba(179, 230, 161, 0.6)",
                        opacity: 0.8,
                        border: "1px solid black",
                      }}
                    ></div>
                    <span className="legend-label">High risk target</span>
                  </div>
                  <div className="legend-item">
                    <div
                      className="legend-color"
                      style={{
                        backgroundColor: "rgba(255, 255, 204, 0.8)",
                        opacity: 0.8,
                        border: "1px solid black",
                      }}
                    ></div>
                    <span className="legend-label">
                      Borderline to intermediate risk target
                    </span>
                  </div>
                </div>
              </div>
            </div>

            {discordance !== null && (
              <div
                className="results-container"
                style={{ justifyContent: "center", marginTop: "20px" }}
              >
                <div className="resultcard">
                  <h4 style={{ margin: "0 0 10px 0" }}>Discordance</h4>
                  <h3
                    style={{
                      margin: "0 0 10px 0",
                      padding: "5px 10px",
                      borderRadius: "5px",
                      backgroundColor:
                        discordance > 5
                          ? "#B85C5C"
                          : discordance < -5
                          ? "#5B7DB1"
                          : "transparent",
                      color:
                        discordance > 5 || discordance < -5
                          ? "white"
                          : "inherit",
                    }}
                  >
                    {Math.round(Math.abs(discordance))}%
                  </h3>
                  <p
                    style={{
                      fontSize: "0.9em",
                      margin: 0,
                      textAlign: "center",
                    }}
                  >
                    {Math.abs(discordance) < 1
                      ? "ApoB is about what would be expected, based on LDL-C level"
                      : Math.abs(discordance) < 5
                      ? "ApoB is close to what would be expected, based on LDL-C level"
                      : `ApoB is ${Math.round(Math.abs(discordance))}% ${
                          discordance > 0 ? "higher" : "lower"
                        } than would be expected, based on LDL-C level`}
                  </p>
                </div>
              </div>
            )}
          </div>

          <div className="mt-4">
            <Link to="/" className="home-button">
              Home
            </Link>
            <CollapsibleTab links={links} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LDLApoB;
