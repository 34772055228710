// src/ProjectedMitralGradient.js
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css"; // Import custom styles
import Menu from "./Menu"; // Import the Menu component
import Footer from "./Footer";
import CollapsibleTab from "./CollapsibleTab";

const links = [
  {
    href: "https://www.jacc.org/doi/10.1016/j.jcmg.2020.12.013",
    text: "A Novel Assessment Using Projected Transmitral Gradient Improves Diagnostic Yield of Doppler Hemodynamics in Rheumatic and Calcific Mitral Stenosis",
  },
];
const ProjectedMitralGradient = () => {
  const [gender, setGender] = useState("Male");
  const [heartRate, setHeartRate] = useState("");
  const [strokeVolume, setStrokeVolume] = useState("");
  const [measuredTMG, setMeasuredTMG] = useState("");
  const [projectedTMG, setProjectedTMG] = useState(null);
  const [showMenu, setShowMenu] = useState(window.innerWidth > 768);

  useEffect(() => {
    const handleResize = () => {
      setShowMenu(window.innerWidth > 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const hr = parseFloat(heartRate);
    const sv = parseFloat(strokeVolume);
    const measured = parseFloat(measuredTMG);

    if (isNaN(hr) || isNaN(sv) || isNaN(measured) || !gender) {
      setProjectedTMG(null);
      return;
    }

    let adjustment = 0;

    if (gender === "Male") {
      adjustment = 0.07 * (hr - 70) + 0.03 * (sv - 97);
    } else {
      adjustment = 0.08 * (hr - 72) + 0.04 * (sv - 84);
    }

    const tmg = measured - adjustment;
    setProjectedTMG(tmg);
  }, [gender, heartRate, strokeVolume, measuredTMG]);

  return (
    <div className="page_div" data-component="projected-mitral-gradient">
      {showMenu && (
        <div className="menu_div">
          <Menu />
        </div>
      )}

      <div className="content_div">
        <h1 className="title">Projected Transmitral Gradient Calculator</h1>
        <div className="mb-3">
          <label className="form-label">Gender:</label>
          <div>
            <button
              className={`gender-button ${
                gender === "Male" ? "selected" : "unselected"
              }`}
              onClick={() => setGender("Male")}
            >
              Male
            </button>
            <button
              className={`gender-button ${
                gender === "Female" ? "selected" : "unselected"
              }`}
              onClick={() => setGender("Female")}
            >
              Female
            </button>
          </div>
        </div>

        <div className="mb-3">
          <label htmlFor="measuredTMG" className="form-label">
            Measured Transmitral Gradient:
          </label>
          <input
            type="number"
            id="measuredTMG"
            className="form-control"
            value={measuredTMG}
            onChange={(e) => setMeasuredTMG(e.target.value)}
            placeholder="mmHg"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="heartRate" className="form-label">
            Heart Rate:
          </label>
          <input
            type="number"
            id="heartRate"
            className="form-control"
            value={heartRate}
            onChange={(e) => setHeartRate(e.target.value)}
            placeholder="bpm"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="strokeVolume" className="form-label">
            Stroke Volume:
          </label>
          <input
            type="number"
            id="strokeVolume"
            className="form-control"
            value={strokeVolume}
            onChange={(e) => setStrokeVolume(e.target.value)}
            placeholder="ml"
          />
        </div>

        {projectedTMG !== null && (
          <div
            className="mt-3"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div className="resultcard">
              <h4>Projected Transmitral Gradient</h4>
              <h3>
                <strong>{projectedTMG.toFixed(1)} mmHg</strong>
              </h3>
            </div>
          </div>
        )}
        <div className="mt-4">
          <Link to="/" className="home-button">
            Home
          </Link>
          <CollapsibleTab links={links} />
        </div>
      </div>
    </div>
  );
};
export default ProjectedMitralGradient;
