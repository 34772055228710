// src/PrValveNmls.js
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./App.css"; // Import custom styles
import Menu from "./Menu"; // Import the Menu component
import Footer from "./Footer";
import CollapsibleTab from "./CollapsibleTab";
import { getAllValves, valveTypeLabels } from "./data/valveData";

const links = [
  {
    href: "https://www.asecho.org/guideline/guidelines-for-the-evaluation-of-prosthetic-valve-function-with-cardiovascular-imaging-a-report-from-the-american-society-of-echocardiography-developed-in-collaboration-with-the-society-for-cardiovas/",
    text: "2024 American Society of Echocardiography Guidelines for the Evaluation of Prosthetic Valve Function With Cardiovascular Imaging:",
  },
];

// Sample valve data
const valvesData = [
  {
    name: "Sapien",
    type: "Transcatheter",
    position: "Aortic",
    delivery: "None",
    sizes: [
      {
        size: "23 mm",
        meanEOA: { value: 1.56, sd: 0.43 },
        meanGradient: { value: 9.92, sd: 4.27 },
        DVI: { value: 0.53, sd: 0.13 },
      },
      {
        size: "26 mm",
        meanEOA: { value: 1.84, sd: 0.52 },
        meanGradient: { value: 8.76, sd: 3.89 },
        DVI: { value: 0.53, sd: 0.13 },
      },
    ],
  },
  {
    name: "Sapien XT",
    type: "Transcatheter",
    position: "Aortic",
    delivery: "None",
    sizes: [
      {
        size: "23 mm",
        meanEOA: { value: 1.41, sd: 0.3 },
        meanGradient: { value: 10.41, sd: 3.74 },
        DVI: { value: 0.52, sd: 0.1 },
      },
      {
        size: "26 mm",
        meanEOA: { value: 1.74, sd: 0.42 },
        meanGradient: { value: 9.24, sd: 3.57 },
        DVI: { value: 0.54, sd: 0.11 },
      },
      {
        size: "29 mm",
        meanEOA: { value: 2.06, sd: 0.52 },
        meanGradient: { value: 8.36, sd: 3.14 },
        DVI: { value: 0.53, sd: 0.11 },
      },
    ],
  },
  {
    name: "Sapien S3",
    type: "Transcatheter",
    position: "Aortic",
    delivery: "None",
    sizes: [
      {
        size: "20 mm",
        meanEOA: { value: 1.22, sd: 0.22 },
        meanGradient: { value: 16.23, sd: 5.01 },
        DVI: { value: 0.42, sd: 0.07 },
      },
      {
        size: "23 mm",
        meanEOA: { value: 1.45, sd: 0.26 },
        meanGradient: { value: 12.79, sd: 4.65 },
        DVI: { value: 0.423, sd: 0.08 },
      },
      {
        size: "26 mm",
        meanEOA: { value: 1.74, sd: 0.35 },
        meanGradient: { value: 19.59, sd: 3.88 },
        DVI: { value: 0.43, sd: 0.09 },
      },
      {
        size: "29 mm",
        meanEOA: { value: 1.89, sd: 0.37 },
        meanGradient: { value: 9.28, sd: 3.16 },
        DVI: { value: 0.4, sd: 0.09 },
      },
    ],
  },
  // Add more valves as needed...
];

// Filter function
const filterValves = (searchTerm) => {
  if (!searchTerm.trim()) return [];

  const allValves = getAllValves();
  const searchLower = searchTerm.toLowerCase().trim();

  return allValves.filter((valve) => {
    const valveName = valve.name.toLowerCase();

    // Try exact match first
    if (valveName === searchLower) return true;

    // Split valve name on spaces and hyphens
    const valveNameWords = valveName.split(/[\s-]+/);

    // For single word searches, match exact words only
    if (!searchLower.includes(" ")) {
      return valveNameWords.some((word) => word === searchLower);
    }

    // For multi-word searches, all words must match exactly
    const searchWords = searchLower.split(/\s+/);
    return searchWords.every((searchWord) =>
      valveNameWords.some((nameWord) => nameWord === searchWord)
    );
  });
};

const PrValveNmls = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filterType, setFilterType] = useState("all"); // Optional: for filtering by type
  const [showResults, setShowResults] = useState(false);
  const [showMenu, setShowMenu] = useState(window.innerWidth > 768);

  useEffect(() => {
    const handleResize = () => {
      setShowMenu(window.innerWidth > 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleSearch = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    setShowResults(term.trim() !== "");
  };

  const filteredValves = filterValves(searchTerm);

  return (
    <div className="page_div" data-component="pr-valve-nmls">
      {showMenu && (
        <div className="menu_div">
          <Menu />
        </div>
      )}

      <div className="content_div">
        <h1 className="title">Prosthetic Valve Normals</h1>
        <div className="search-container">
          <input
            type="text"
            className="search-input"
            placeholder="Search valves..."
            value={searchTerm}
            onChange={handleSearch}
          />
        </div>

        <div className={`valve-list ${showResults ? "show" : ""}`}>
          {filteredValves.length > 0 ? (
            filteredValves.map((valve) => (
              <div key={valve.name} className="valve-card">
                <h3 className="valve-title">{valve.name}</h3>
                <div className="valve-info">
                  <span className="valve-type">
                    {valveTypeLabels[valve.type] || valve.type}
                  </span>
                  <span className="valve-position">
                    {valveTypeLabels[valve.position] || valve.position}
                  </span>
                </div>
                <div className="valve-sizes">
                  {valve.sizes.map((size) => (
                    <div key={size.size} className="size-item">
                      <h4 className="size-title">{size.size}</h4>
                      <ul className="size-details">
                        <li>
                          Mean EOA:{" "}
                          {size.measurements
                            ? size.measurements.meanEOA.value
                            : size.meanEOA.value}{" "}
                          ±{" "}
                          {size.measurements
                            ? size.measurements.meanEOA.sd
                            : size.meanEOA.sd}
                        </li>
                        <li>
                          Mean Gradient:{" "}
                          {size.measurements
                            ? size.measurements.meanGradient.value
                            : size.meanGradient.value}{" "}
                          ±{" "}
                          {size.measurements
                            ? size.measurements.meanGradient.sd
                            : size.meanGradient.sd}
                        </li>
                        {(size.measurements?.DVI || size.DVI) && (
                          <li>
                            DVI:{" "}
                            {size.measurements
                              ? size.measurements.DVI.value
                              : size.DVI.value}{" "}
                            ±{" "}
                            {size.measurements
                              ? size.measurements.DVI.sd
                              : size.DVI.sd}
                          </li>
                        )}
                        {size.measurements?.peakGradient?.value &&
                          size.measurements.peakGradient.value !== "NA" && (
                            <li>
                              Peak Gradient:{" "}
                              {size.measurements.peakGradient.value} ±{" "}
                              {size.measurements.peakGradient.sd}
                            </li>
                          )}
                      </ul>
                    </div>
                  ))}
                </div>
              </div>
            ))
          ) : searchTerm.trim() ? (
            <p className="no-results">No valves found.</p>
          ) : null}
        </div>

        <div className="mt-4">
          <Link to="/" className="home-button">
            Home
          </Link>
          <CollapsibleTab links={links} />
        </div>
      </div>
    </div>
  );
};

export default PrValveNmls;
