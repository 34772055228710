import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Menu from "./Menu";
import "./App.css";
import Footer from "./Footer";
import CollapsibleTab from "./CollapsibleTab";
import { getThresholds } from "./data/lvhData";

const links = [
  {
    href: "https://www.jacc.org/doi/abs/10.1016/j.jacc.2024.10.082",
    text: "Demographic-Based Personalized Left Ventricular Hypertrophy Thresholds for Hypertrophic Cardiomyopathy Diagnosis",
  },
];

const LvhThresholds = () => {
  const [lvmass, setLvmass] = useState("");
  const [bsa, setBsa] = useState("");
  const [height, setHeight] = useState("");
  const [weight, setWeight] = useState("");
  const [isBsaMode, setIsBsaMode] = useState(true);
  const [gender, setGender] = useState("male");
  const [age, setAge] = useState("");
  const [maxLvWallThickness, setMaxLvWallThickness] = useState("");
  const [result, setResult] = useState(null);
  const [showMenu, setShowMenu] = useState(window.innerWidth > 768);

  useEffect(() => {
    const handleResize = () => {
      setShowMenu(window.innerWidth > 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Function to get the floor BSA bracket
  const getBsaBracket = (bsa, gender) => {
    const bsaNum = parseFloat(bsa);
    if (gender === "male") {
      if (bsaNum < 1.7) return 1.7;
      if (bsaNum < 1.8) return 1.7;
      if (bsaNum < 1.9) return 1.8;
      if (bsaNum < 2.0) return 1.9;
      if (bsaNum < 2.1) return 2.0;
      if (bsaNum < 2.2) return 2.1;
      if (bsaNum < 2.3) return 2.2;
      return 2.3;
    } else {
      if (bsaNum < 1.5) return 1.5;
      if (bsaNum < 1.6) return 1.5;
      if (bsaNum < 1.7) return 1.6;
      if (bsaNum < 1.8) return 1.7;
      if (bsaNum < 1.9) return 1.8;
      if (bsaNum < 2.0) return 1.9;
      if (bsaNum < 2.1) return 2.0;
      return 2.1;
    }
  };

  // Function to get the floor age bracket
  const getAgeBracket = (age) => {
    const ageNum = parseInt(age);
    if (ageNum < 25) return 20;
    if (ageNum < 30) return 25;
    if (ageNum < 35) return 30;
    if (ageNum < 40) return 35;
    if (ageNum < 45) return 40;
    if (ageNum < 50) return 45;
    if (ageNum < 55) return 50;
    if (ageNum < 60) return 55;
    if (ageNum < 65) return 60;
    if (ageNum < 70) return 65;
    if (ageNum < 75) return 70;
    if (ageNum < 80) return 75;
    return 80;
  };

  // Calculate BSA when height and weight change
  useEffect(() => {
    if (!isBsaMode && height && weight) {
      // Dubois formula
      const calculatedBsa =
        0.007184 * Math.pow(height, 0.725) * Math.pow(weight, 0.425);
      setBsa(calculatedBsa.toFixed(2));
    }
  }, [height, weight, isBsaMode]);

  // Calculate result automatically when all required fields are filled
  useEffect(() => {
    if (bsa && age) {
      const bracketAge = getAgeBracket(age);
      const bracketBsa = getBsaBracket(bsa, gender);
      const thresholds = getThresholds(bracketBsa, bracketAge, gender);

      if (maxLvWallThickness) {
        const wallThickness = parseFloat(maxLvWallThickness);
        if (wallThickness >= thresholds["95"]) {
          setResult("Evidence of LV Hypertrophy");
        } else {
          setResult("No LV Hypertrophy");
        }
      } else {
        setResult(`Threshold for LV Hypertrophy: ${thresholds["95"]} mm`);
      }
    } else {
      setResult(null);
    }
  }, [bsa, age, maxLvWallThickness, gender]);

  const toggleBsaMode = () => {
    setIsBsaMode(!isBsaMode);
    if (isBsaMode) {
      setHeight("");
      setWeight("");
    } else {
      setBsa("");
    }
  };

  return (
    <div className="page_div" data-component="lvh-thresholds">
      {showMenu && (
        <div className="menu_div">
          <Menu />
        </div>
      )}

      <div className="content_div">
        <h1 className="title">Demographic-Based LVH Thresholds</h1>

        <div className="calculator-container" style={{ maxWidth: "90%" }}>
          {/* Gender Selection Row */}
          <div className="row mb-3">
            <div className="col-12 d-flex justify-content-center">
              <div>
                <label className="form-label text-center d-block">
                  Gender:
                </label>
                <div style={{ display: "flex", gap: "10px" }}>
                  <button
                    className={`gender-button ${
                      gender === "male" ? "selected" : "unselected"
                    }`}
                    onClick={() => setGender("male")}
                  >
                    Male
                  </button>
                  <button
                    className={`gender-button ${
                      gender === "female" ? "selected" : "unselected"
                    }`}
                    onClick={() => setGender("female")}
                  >
                    Female
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* Main Inputs Row */}
          <div className="row mb-3">
            {/* BSA Input Section */}
            <div className="col-md-4">
              <label
                htmlFor="bsa"
                className="form-label"
                style={{ whiteSpace: "nowrap" }}
              >
                {isBsaMode ? (
                  <>
                    <button className="btn-fit-text" onClick={toggleBsaMode}>
                      Calculate
                    </button>{" "}
                    BSA
                  </>
                ) : (
                  <>
                    <button className="btn-fit-text" onClick={toggleBsaMode}>
                      Enter
                    </button>{" "}
                    BSA
                  </>
                )}
              </label>
              {isBsaMode ? (
                <input
                  type="number"
                  id="bsa"
                  className="form-control"
                  value={bsa}
                  onChange={(e) => setBsa(e.target.value)}
                  placeholder="Enter BSA (m²)"
                  step="0.01"
                />
              ) : (
                <>
                  <div className="row">
                    <div className="col-md-6">
                      <input
                        type="number"
                        id="height"
                        className="form-control"
                        value={height}
                        onChange={(e) => setHeight(e.target.value)}
                        placeholder="Height (cm)"
                        step="0.1"
                      />
                    </div>
                    <div className="col-md-6">
                      <input
                        type="number"
                        id="weight"
                        className="form-control"
                        value={weight}
                        onChange={(e) => setWeight(e.target.value)}
                        placeholder="Weight (kg)"
                        step="0.1"
                      />
                    </div>
                  </div>
                </>
              )}
            </div>

            {/* Age Input */}
            <div className="col-md-4">
              <label htmlFor="age" className="form-label">
                Age:
              </label>
              <input
                type="number"
                id="age"
                className="form-control"
                value={age}
                onChange={(e) => setAge(e.target.value)}
                placeholder="Years"
                min="20"
                max="80"
              />
            </div>

            {/* Maximum LV Wall Thickness Input */}
            <div className="col-md-4">
              <label htmlFor="maxLvWallThickness" className="form-label">
                Maximum LV Wall Thickness:
              </label>
              <input
                type="number"
                id="maxLvWallThickness"
                className="form-control"
                value={maxLvWallThickness}
                onChange={(e) => setMaxLvWallThickness(e.target.value)}
                placeholder="mm"
                step="0.1"
              />
            </div>
          </div>

          {result && (
            <div className="d-flex justify-content-center">
              <div className="resultcard">
                <h5
                  style={{ margin: 0, textAlign: "center", fontWeight: "bold" }}
                >
                  {result}
                </h5>
              </div>
            </div>
          )}
        </div>

        <div className="mt-4">
          <Link to="/" className="home-button">
            Home
          </Link>
          <CollapsibleTab links={links} />
        </div>
      </div>
    </div>
  );
};

export default LvhThresholds;
