// CMRFlow.js

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./App.css"; // Import custom styles
import Menu from "./Menu"; // Import the Menu component
import Footer from "./Footer"; // Import the Footer component (if applicable)
import CollapsibleTab from "./CollapsibleTab";

const CMRFlow = () => {
  const [lvsv, setLvsv] = useState("");
  const [measurements, setMeasurements] = useState({
    aortaForwardFlow: [""],
    aortaDiastolicFlow: [""],
  });
  const [paMeasurements, setPaMeasurements] = useState({
    paForwardFlow: [""],
    paDiastolicFlow: [""],
  });
  const [rvStrokeVolume, setRvStrokeVolume] = useState("");

  const [qpQs, setQpQs] = useState(null);
  const [rVolMR, setRVolMR] = useState(null);
  const [rFracMR, setRFracMR] = useState(null);
  const [rVolAR, setRVolAR] = useState(null);
  const [rFracAR, setRFracAR] = useState(null);
  const [rVolTR, setRVolTR] = useState(null);
  const [rFracTR, setRFracTR] = useState(null);
  const [rVolPR, setRVolPR] = useState(null);
  const [rFracPR, setRFracPR] = useState(null);

  const [conditions, setConditions] = useState({
    aorticRegurgitation: false,
    mitralRegurgitation: false,
    tricuspidRegurgitation: false,
    pulmonicRegurgitation: false,
    atrialSeptalDefect: false,
  });

  const [showMenu, setShowMenu] = useState(window.innerWidth > 768);

  useEffect(() => {
    const handleResize = () => {
      setShowMenu(window.innerWidth > 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleMeasurementChange = (type, index, value) => {
    const newMeasurements = { ...measurements };
    newMeasurements[type][index] = value;
    setMeasurements(newMeasurements);
  };

  const addFields = () => {
    if (measurements.aortaForwardFlow.length < 5) {
      setMeasurements({
        aortaForwardFlow: [...measurements.aortaForwardFlow, ""],
        aortaDiastolicFlow: [...measurements.aortaDiastolicFlow, ""],
      });
    }
  };

  const removeField = (index) => {
    if (measurements.aortaForwardFlow.length > 1) {
      setMeasurements({
        aortaForwardFlow: measurements.aortaForwardFlow.filter(
          (_, i) => i !== index
        ),
        aortaDiastolicFlow: measurements.aortaDiastolicFlow.filter(
          (_, i) => i !== index
        ),
      });
    }
  };

  const handlePaMeasurementChange = (type, index, value) => {
    const newPaMeasurements = { ...paMeasurements };
    newPaMeasurements[type][index] = value;
    setPaMeasurements(newPaMeasurements);
  };

  const addPaFields = () => {
    if (paMeasurements.paForwardFlow.length < 5) {
      setPaMeasurements({
        paForwardFlow: [...paMeasurements.paForwardFlow, ""],
        paDiastolicFlow: [...paMeasurements.paDiastolicFlow, ""],
      });
    }
  };

  const removePaField = (index) => {
    if (paMeasurements.paForwardFlow.length > 1) {
      setPaMeasurements({
        paForwardFlow: paMeasurements.paForwardFlow.filter(
          (_, i) => i !== index
        ),
        paDiastolicFlow: paMeasurements.paDiastolicFlow.filter(
          (_, i) => i !== index
        ),
      });
    }
  };

  const calculateMetrics = () => {
    // Utility function to check if all values in an array are valid numbers
    const allValidNumbers = (arr) =>
      arr.every((val) => !isNaN(parseFloat(val)) && isFinite(val));

    const LVSV = parseFloat(lvsv);
    const RVSV = parseFloat(rvStrokeVolume);

    const aortaForwardFlow = measurements.aortaForwardFlow.map(parseFloat);
    const aortaDiastolicFlow = measurements.aortaDiastolicFlow.map(parseFloat);

    const paForwardFlow = paMeasurements.paForwardFlow.map(parseFloat);
    const paDiastolicFlow = paMeasurements.paDiastolicFlow.map(parseFloat);

    // Validate based on conditions
    if (conditions.mitralRegurgitation) {
      if (
        !allValidNumbers(aortaForwardFlow) ||
        !allValidNumbers(aortaDiastolicFlow) ||
        isNaN(LVSV)
      ) {
        alert(
          "Please enter valid numbers for all required fields for Mitral Regurgitation."
        );
        return;
      }
    }

    if (conditions.tricuspidRegurgitation) {
      if (
        !allValidNumbers(paForwardFlow) ||
        !allValidNumbers(paDiastolicFlow) ||
        isNaN(RVSV)
      ) {
        alert(
          "Please enter valid numbers for all required fields for Tricuspid Regurgitation."
        );
        return;
      }
    }

    if (conditions.pulmonicRegurgitation) {
      if (
        !allValidNumbers(paForwardFlow) ||
        !allValidNumbers(paDiastolicFlow)
      ) {
        alert(
          "Please enter valid numbers for all required fields for Pulmonic Regurgitation."
        );
        return;
      }
    }

    if (conditions.atrialSeptalDefect) {
      if (
        !allValidNumbers(aortaForwardFlow) ||
        !allValidNumbers(paForwardFlow)
      ) {
        alert(
          "Please enter valid numbers for all required fields for Atrial Septal Defect."
        );
        return;
      }
    }

    // Calculations
    const averageAortaForwardFlow =
      aortaForwardFlow.reduce((sum, val) => sum + val, 0) /
      aortaForwardFlow.length;
    const averageAortaDiastolicFlow =
      aortaDiastolicFlow.reduce((sum, val) => sum + val, 0) /
      aortaDiastolicFlow.length;
    const averagePAForwardFlow =
      paForwardFlow.reduce((sum, val) => sum + val, 0) / paForwardFlow.length;
    const averagePADiastolicFlow =
      paDiastolicFlow.reduce((sum, val) => sum + val, 0) /
      paDiastolicFlow.length;

    const qpQs = conditions.atrialSeptalDefect
      ? (averagePAForwardFlow / averageAortaForwardFlow).toFixed(1)
      : null;

    // Logic for Tricuspid Regurgitant Volume
    const RVolTR = conditions.tricuspidRegurgitation
      ? (RVSV - (averagePAForwardFlow + averagePADiastolicFlow)).toFixed(1)
      : null;

    const RFracTR = conditions.tricuspidRegurgitation
      ? ((RVolTR / RVSV) * 100).toFixed(1)
      : null;

    const RVolAR = conditions.aorticRegurgitation
      ? averageAortaDiastolicFlow.toFixed(1)
      : null;
    const RFracAR = conditions.aorticRegurgitation
      ? ((RVolAR / averageAortaForwardFlow) * 100).toFixed(1)
      : null;

    const RVolPR = conditions.pulmonicRegurgitation
      ? averagePADiastolicFlow.toFixed(1)
      : null;
    const RFracPR = conditions.pulmonicRegurgitation
      ? averagePAForwardFlow
        ? ((RVolPR / averagePAForwardFlow) * 100).toFixed(1)
        : "0.0"
      : null;

    // Logic for Mitral Regurgitant Volume
    const RVolMR = conditions.mitralRegurgitation
      ? (LVSV - (averageAortaForwardFlow + averageAortaDiastolicFlow)).toFixed(
          1
        )
      : null;
    const RFracMR = conditions.mitralRegurgitation
      ? ((RVolMR / LVSV) * 100).toFixed(1)
      : null;

    // Validation for negative results
    if (
      RVolAR < 0 ||
      RFracAR < 0 ||
      RVolMR < 0 ||
      RFracMR < 0 ||
      RVolTR < 0 ||
      RFracTR < 0 ||
      RVolPR < 0 ||
      RFracPR < 0 ||
      qpQs < 0
    ) {
      alert("Undefined: parameter cannot be negative");
      return;
    }

    setRVolAR(RVolAR);
    setQpQs(qpQs);
    setRVolMR(RVolMR);
    setRFracMR(RFracMR);
    setRVolTR(RVolTR);
    setRFracTR(RFracTR);
    setRFracAR(RFracAR);
    setRVolPR(RVolPR);
    setRFracPR(RFracPR);
  };

  const toggleCondition = (condition) => {
    setConditions((prevConditions) => ({
      ...prevConditions,
      [condition]: !prevConditions[condition],
    }));
  };

  const links = [
    {
      href: "https://www.jacc.org/doi/full/10.1016/j.jacc.2017.12.009",
      text: "Use of Cardiac Magnetic Resonance Imaging in Assessing Mitral Regurgitation: Current Evidence",
    },
  ];

  return (
    <div className="page_div" data-component="cmr-flow">
      {showMenu && (
        <div className="menu_div">
          <Menu />
        </div>
      )}

      <div className="content_div">
        <h1 className="title">CMR Flow Calculator</h1>

        {/* Condition Selection Buttons */}
        <div className="custom-button-row">
          <button
            className={`circular-button ${
              conditions.aorticRegurgitation ? "selected" : ""
            }`}
            onClick={() => toggleCondition("aorticRegurgitation")}
          >
            AR
          </button>
          <button
            className={`circular-button ${
              conditions.mitralRegurgitation ? "selected" : ""
            }`}
            onClick={() => toggleCondition("mitralRegurgitation")}
          >
            MR
          </button>
          <button
            className={`circular-button ${
              conditions.tricuspidRegurgitation ? "selected" : ""
            }`}
            onClick={() => toggleCondition("tricuspidRegurgitation")}
          >
            TR
          </button>
          <button
            className={`circular-button ${
              conditions.pulmonicRegurgitation ? "selected" : ""
            }`}
            onClick={() => toggleCondition("pulmonicRegurgitation")}
          >
            PR
          </button>
          <button
            className={`circular-button ${
              conditions.atrialSeptalDefect ? "selected" : ""
            }`}
            onClick={() => toggleCondition("atrialSeptalDefect")}
          >
            ASD
          </button>
        </div>

        <div className="form-section">
          {(conditions.aorticRegurgitation ||
            conditions.mitralRegurgitation ||
            conditions.atrialSeptalDefect) && (
            <>
              <div className="large-input-group">
                <div className="input-group">
                  <div className="inputfield-w-title">
                    <label htmlFor="aortaForwardFlow" className="form-label">
                      Aorta Forward Flow:
                    </label>
                    {measurements.aortaForwardFlow.map((measurement, index) => (
                      <div key={index} className="input-item">
                        <input
                          type="number"
                          value={measurement}
                          onChange={(e) =>
                            handleMeasurementChange(
                              "aortaForwardFlow",
                              index,
                              e.target.value
                            )
                          }
                          placeholder="mL"
                        />
                      </div>
                    ))}
                  </div>

                  <div className="inputfield-w-title">
                    <label htmlFor="aortaDiastolicFlow" className="form-label">
                      Aorta Diastolic Flow:
                    </label>
                    {measurements.aortaDiastolicFlow.map(
                      (measurement, index) => (
                        <div key={index} className="input-item">
                          <input
                            type="number"
                            value={measurement}
                            onChange={(e) =>
                              handleMeasurementChange(
                                "aortaDiastolicFlow",
                                index,
                                e.target.value
                              )
                            }
                            placeholder="mL"
                          />
                        </div>
                      )
                    )}
                  </div>
                </div>
                <div className="button-group-row">
                  <div className="button-group">
                    <button className="addlmeasure-button" onClick={addFields}>
                      Add Aorta Measurement
                    </button>
                    <button
                      className="addlmeasure-button"
                      onClick={() =>
                        removeField(measurements.aortaForwardFlow.length - 1)
                      }
                    >
                      Remove Last Aorta Measurement
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}

          {(conditions.tricuspidRegurgitation ||
            conditions.atrialSeptalDefect ||
            conditions.pulmonicRegurgitation) && (
            <>
              <div className="large-input-group">
                <div className="input-group">
                  <div className="inputfield-w-title">
                    <label htmlFor="paForwardFlow" className="form-label">
                      PA Forward Flow:
                    </label>
                    {paMeasurements.paForwardFlow.map((measurement, index) => (
                      <div key={index} className="input-item">
                        <input
                          type="number"
                          value={measurement}
                          onChange={(e) =>
                            handlePaMeasurementChange(
                              "paForwardFlow",
                              index,
                              e.target.value
                            )
                          }
                          placeholder="mL"
                        />
                      </div>
                    ))}
                  </div>

                  <div className="inputfield-w-title">
                    <label htmlFor="paDiastolicFlow" className="form-label">
                      PA Diastolic Flow:
                    </label>
                    {paMeasurements.paDiastolicFlow.map(
                      (measurement, index) => (
                        <div key={index} className="input-item">
                          <input
                            type="number"
                            value={measurement}
                            onChange={(e) =>
                              handlePaMeasurementChange(
                                "paDiastolicFlow",
                                index,
                                e.target.value
                              )
                            }
                            placeholder="mL"
                          />
                        </div>
                      )
                    )}
                  </div>
                </div>
                <div className="button-group">
                  <button className="addlmeasure-button" onClick={addPaFields}>
                    Add PA Measurement
                  </button>
                  <button
                    className="addlmeasure-button"
                    onClick={() =>
                      removePaField(paMeasurements.paForwardFlow.length - 1)
                    }
                  >
                    Remove Last PA Measurement
                  </button>
                </div>
              </div>
            </>
          )}

          <div className="input-group">
            {conditions.mitralRegurgitation && (
              <div className="inputfield-w-title">
                <label htmlFor="lvsv" className="form-label">
                  Left Ventricular Stroke Volume (Cine Short Axis):
                </label>
                <div className="input-item">
                  <input
                    type="number"
                    id="lvsv"
                    value={lvsv}
                    onChange={(e) => setLvsv(e.target.value)}
                    placeholder="mL"
                  />
                </div>
              </div>
            )}

            {conditions.tricuspidRegurgitation && (
              <div className="inputfield-w-title">
                <label htmlFor="rvStrokeVolume" className="form-label">
                  Right Ventricular Stroke Volume (Cine SAX):
                </label>
                <div className="input-item">
                  <input
                    type="number"
                    id="rvStrokeVolume"
                    value={rvStrokeVolume}
                    onChange={(e) => setRvStrokeVolume(e.target.value)}
                    placeholder="mL"
                  />
                </div>
              </div>
            )}
          </div>
        </div>

        <button className="calculate-button" onClick={calculateMetrics}>
          Calculate Flows
        </button>

        <div className="results-container">
          {conditions.aorticRegurgitation && rVolAR !== null && (
            <div
              className="mt-3"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div className="resultcard">
                <h4>Aortic Regurgitation</h4>
                <h5>
                  Regurgitant Volume: <strong>{rVolAR} mL</strong>
                </h5>
                <h5>
                  Regurgitant Fraction: <strong>{rFracAR}%</strong>
                </h5>
              </div>
            </div>
          )}

          {conditions.mitralRegurgitation && rVolMR !== null && (
            <div
              className="mt-3"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div className="resultcard">
                <h4>Mitral Regurgitation</h4>
                <h5>
                  Regurgitant Volume: <strong>{rVolMR} mL</strong>
                </h5>
                <h5>
                  Regurgitant Fraction: <strong>{rFracMR}%</strong>
                </h5>
              </div>
            </div>
          )}

          {conditions.tricuspidRegurgitation && rVolTR !== null && (
            <div
              className="mt-3"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div className="resultcard">
                <h4>Tricuspid Regurgitation</h4>
                <h5>
                  Regurgitant Volume: <strong>{rVolTR} mL</strong>
                </h5>
                <h5>
                  Regurgitant Fraction: <strong>{rFracTR}%</strong>
                </h5>
              </div>
            </div>
          )}

          {conditions.pulmonicRegurgitation && rVolPR !== null && (
            <div
              className="mt-3"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div className="resultcard">
                <h4>Pulmonic Regurgitation</h4>
                <h5>
                  Regurgitant Volume: <strong>{rVolPR} mL</strong>
                </h5>
                <h5>
                  Regurgitant Fraction: <strong>{rFracPR}%</strong>
                </h5>
              </div>
            </div>
          )}

          {conditions.atrialSeptalDefect && qpQs !== null && (
            <div
              className="mt-3"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div className="resultcard">
                <h4>Atrial Septal Defect</h4>
                <h5>
                  Qp:Qs: <strong>{qpQs}</strong>
                </h5>
              </div>
            </div>
          )}
        </div>

        <div className="mt-4">
          <Link to="/" className="home-button">
            Home
          </Link>
          <CollapsibleTab links={links} />
        </div>
      </div>
    </div>
  );
};

export default CMRFlow;
