// src/App.js
import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import "./App.css";
import Home from "./Home";
import Calculator from "./Calculator";
import ECVCalculator from "./ECVCalculator";
import METsCalculator from "./METsCalculator";
import Header from "./Header";
import Footer from "./Footer";
import GuidelineAssistant from "./GuidelineAssistant"; // Ensure correct import
import Disclaimer from "./Disclaimer";
import FickCalculator from "./FickCalculator";
import MatomoTracker from "./components/MatomoTracker";
import LgeRiskEstimator from "./LgeRiskEstimator";
import LDLApoB from "./LDLApoB";
import LvhThresholds from "./LvhThresholds";
import HRCalculator from "./HRCalculator";
import ChronotropIndex from "./ChronotropIndex";
import RaPressure from "./RaPressure";
import { trackPageVisit } from "./utils/usageTracker";

import "bootstrap/dist/css/bootstrap.min.css";
import CMRFlow from "./CMRFlow";
import ProjectedMitralGradient from "./ProjectedMitralGradient";
import ProstheticValveNormals from "./PrValveNmls";
import PMGnew from "./PMGnew";

// Component to track route changes
const RouteTracker = () => {
  const location = useLocation();

  React.useEffect(() => {
    // Track the page visit whenever the location changes
    trackPageVisit(location.pathname);
  }, [location]);

  return null;
};

// Wrapper to handle both Matomo and our custom tracking
const AppContent = () => {
  return (
    <>
      <RouteTracker />
      <div className="app-container">
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/metscalculator" element={<METsCalculator />} />
          <Route path="/ecv-calculator" element={<ECVCalculator />} />
          <Route path="/guideline-assistant" element={<GuidelineAssistant />} />
          <Route path="/fickcalculator" element={<FickCalculator />} />
          <Route path="/disclaimer" element={<Disclaimer />} />
          <Route path="/cmrflow" element={<CMRFlow />} />
          <Route path="/tmgradient" element={<ProjectedMitralGradient />} />
          <Route
            path="/prostheticvalves"
            element={<ProstheticValveNormals />}
          />
          <Route path="/lge-risk" element={<LgeRiskEstimator />} />
          <Route path="/ldl-apob" element={<LDLApoB />} />
          <Route path="/lvh-thresholds" element={<LvhThresholds />} />
          <Route path="/hr-calculator" element={<HRCalculator />} />
          <Route path="/chronotrop-index" element={<ChronotropIndex />} />
          <Route path="/ra-pressure" element={<RaPressure />} />
        </Routes>
        <Footer />
      </div>
    </>
  );
};

const App = () => {
  return (
    <Router>
      <MatomoTracker />
      <AppContent />
    </Router>
  );
};

export default App;
