// Utility for tracking which components are most frequently used
const STORAGE_KEY = "componentUsage";
const PAGE_NAMES = {
  "/metscalculator": "METs Calculator",
  "/ecv-calculator": "ECV Calculator",
  "/guideline-assistant": "Guideline Assistant",
  "/fickcalculator": "Fick Calculator",
  "/cmrflow": "CMR Flows",
  "/tmgradient": "Transmitral Gradients",
  "/prostheticvalves": "Prosthetic Valves",
  "/ldl-apob": "LDL-C, ApoB Comparator",
  "/lge-risk": "LGE Risk Estimator",
  "/lvh-thresholds": "LVH Thresholds",
  "/hr-calculator": "HR Calculator",
  "/disclaimer": "Disclaimer",
};

// Initialize usage data in localStorage if it doesn't exist
const initializeUsageData = () => {
  if (!localStorage.getItem(STORAGE_KEY)) {
    const initialData = {};
    Object.keys(PAGE_NAMES).forEach((path) => {
      initialData[path] = 0;
    });
    localStorage.setItem(STORAGE_KEY, JSON.stringify(initialData));
  }
};

// Track a page visit
export const trackPageVisit = (path) => {
  // Initialize if needed
  initializeUsageData();

  // Only track pages that are in our known components list
  if (PAGE_NAMES[path]) {
    try {
      // Get current data
      const usageData = JSON.parse(localStorage.getItem(STORAGE_KEY)) || {};

      // Increment the count for this path
      usageData[path] = (usageData[path] || 0) + 1;

      // Save back to localStorage
      localStorage.setItem(STORAGE_KEY, JSON.stringify(usageData));
    } catch (error) {
      console.error("Error tracking page visit:", error);
    }
  }
};

// Get the most popular components (top N)
export const getPopularComponents = (limit = 3) => {
  // Initialize if needed
  initializeUsageData();

  try {
    // Get usage data
    const usageData = JSON.parse(localStorage.getItem(STORAGE_KEY)) || {};

    // Convert to array and sort by count (descending)
    const sortedComponents = Object.entries(usageData)
      .map(([path, count]) => ({
        path,
        name: PAGE_NAMES[path] || path,
        count,
      }))
      .sort((a, b) => b.count - a.count);

    // Return the top N components
    return sortedComponents.slice(0, limit);
  } catch (error) {
    console.error("Error getting popular components:", error);
    return [];
  }
};

// Reset usage data (for testing)
export const resetUsageData = () => {
  localStorage.removeItem(STORAGE_KEY);
  initializeUsageData();
};
