// src/Home.js
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Menu from "./Menu";
import "./App.css"; // Import custom styles
import Footer from "./Footer";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate();

  // Define default popular tools
  const defaultPopularTools = [
    {
      path: "/metscalculator",
      name: "METs Calculator",
    },
    {
      path: "/ldl-apob",
      name: "LDL-C, ApoB Comparator",
    },
    {
      path: "/tmgradient",
      name: "Transmitral Gradients",
    },
  ];

  const handleCardClick = (e, path) => {
    e.preventDefault();
    const card = e.currentTarget;
    card.classList.add("clicked");

    // Wait for animation to complete before navigating
    setTimeout(() => {
      navigate(path);
    }, 300); // 300ms delay to match the animation
  };

  return (
    <div className="page_div" data-component="home">
      <div className="menu_div">
        <Menu />
      </div>

      <div className="content_div">
        <h1 style={{ margin: "50px 0 30px" }}>
          Welcome to Cardiology Reference
        </h1>

        <div className="popular-components">
          <h2>Most Used Tools</h2>
          <div className="popular-components-grid">
            {defaultPopularTools.map((component) => (
              <a
                key={component.path}
                href={component.path}
                className="popular-component-card"
                onClick={(e) => handleCardClick(e, component.path)}
              >
                <div className="popular-component-title">{component.name}</div>
              </a>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
