// Organize valves by type
export const valveData = {
  transcatheter: {
    aortic: [
      {
        name: "Sapien (OG)",
        manufacturer: "Edwards",
        sizes: [
          {
            size: "20 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: "NA", sd: "NA" },
              meanEOA: { value: "NA", sd: "NA" },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "23 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 9.92, sd: 4.27 },
              meanEOA: { value: 1.56, sd: 0.43 },
              DVI: { value: 0.53, sd: 0.13 },
            },
          },
          {
            size: "26 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 8.76, sd: 3.89 },
              meanEOA: { value: 1.84, sd: 0.52 },
              DVI: { value: 0.53, sd: 0.13 },
            },
          },
          {
            size: "29 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: "NA", sd: "NA" },
              meanEOA: { value: "NA", sd: "NA" },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "All sizes",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 9.36, sd: 4.13 },
              meanEOA: { value: 1.7, sd: 0.49 },
              DVI: { value: 0.53, sd: 0.13 },
            },
          },
        ],
      },
      {
        name: "CoreValve",
        manufacturer: "Medtronic",
        sizes: [
          {
            size: "23 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanEOA: { value: 1.12, sd: 0.36 },
              meanGradient: { value: 14.43, sd: 5.72 },
              DVI: { value: 0.44, sd: 0.09 },
            },
          },
          {
            size: "26 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanEOA: { value: 1.74, sd: 0.49 },
              meanGradient: { value: 8.27, sd: 3.82 },
              DVI: { value: 0.59, sd: 0.15 },
            },
          },
          {
            size: "29 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanEOA: { value: 1.97, sd: 0.53 },
              meanGradient: { value: 8.85, sd: 4.17 },
              DVI: { value: 0.54, sd: 0.12 },
            },
          },
          {
            size: "31 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanEOA: { value: 2.15, sd: 0.72 },
              meanGradient: { value: 9.55, sd: 3.44 },
              DVI: { value: 0.49, sd: 0.12 },
            },
          },
          {
            size: "All sizes",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanEOA: { value: 1.88, sd: 0.56 },
              meanGradient: { value: 8.85, sd: 4.14 },
              DVI: { value: 0.55, sd: 0.13 },
            },
          },
        ],
      },
      {
        name: "Evolut R",
        manufacturer: "Medtronic",
        sizes: [
          {
            size: "23 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanEOA: { value: 1.09, sd: 0.26 },
              meanGradient: { value: 14.97, sd: 7.15 },
              DVI: { value: 0.42, sd: 0.04 },
            },
          },
          {
            size: "26 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanEOA: { value: 1.69, sd: 0.4 },
              meanGradient: { value: 7.53, sd: 2.65 },
              DVI: { value: 0.61, sd: 0.13 },
            },
          },
          {
            size: "29 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanEOA: { value: 1.97, sd: 0.54 },
              meanGradient: { value: 7.85, sd: 3.08 },
              DVI: { value: 0.59, sd: 0.14 },
            },
          },
          {
            size: "34 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanEOA: { value: 2.6, sd: 0.75 },
              meanGradient: { value: 6.3, sd: 3.23 },
              DVI: { value: 0.58, sd: 0.15 },
            },
          },
          {
            size: "All sizes",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanEOA: { value: 2.01, sd: 0.65 },
              meanGradient: { value: 7.52, sd: 3.19 },
              DVI: { value: 0.59, sd: 0.14 },
            },
          },
        ],
      },
      {
        name: "Sapien XT",
        manufacturer: "Edwards",
        sizes: [
          {
            size: "20 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: "NA", sd: "NA" },
              meanEOA: { value: "NA", sd: "NA" },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "23 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 10.41, sd: 3.74 },
              meanEOA: { value: 1.41, sd: 0.3 },
              DVI: { value: 0.52, sd: 0.1 },
            },
          },
          {
            size: "26 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 9.24, sd: 3.57 },
              meanEOA: { value: 1.74, sd: 0.42 },
              DVI: { value: 0.54, sd: 0.11 },
            },
          },
          {
            size: "29 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 8.36, sd: 3.14 },
              meanEOA: { value: 2.06, sd: 0.52 },
              DVI: { value: 0.53, sd: 0.11 },
            },
          },
          {
            size: "All sizes",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 9.52, sd: 3.64 },
              meanEOA: { value: 1.67, sd: 0.46 },
              DVI: { value: 0.53, sd: 0.11 },
            },
          },
        ],
      },
      {
        name: "Sapien S3",
        manufacturer: "Edwards",
        sizes: [
          {
            size: "20 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 16.23, sd: 5.01 },
              meanEOA: { value: 1.22, sd: 0.22 },
              DVI: { value: 0.42, sd: 0.07 },
            },
          },
          {
            size: "23 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 12.79, sd: 4.65 },
              meanEOA: { value: 1.45, sd: 0.26 },
              DVI: { value: 0.43, sd: 0.08 },
            },
          },
          {
            size: "26 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 10.59, sd: 3.88 },
              meanEOA: { value: 1.74, sd: 0.35 },
              DVI: { value: 0.43, sd: 0.09 },
            },
          },
          {
            size: "29 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 9.28, sd: 3.16 },
              meanEOA: { value: 1.89, sd: 0.37 },
              DVI: { value: 0.4, sd: 0.09 },
            },
          },
          {
            size: "All sizes",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 11.18, sd: 4.35 },
              meanEOA: { value: 1.66, sd: 0.38 },
              DVI: { value: 0.43, sd: 0.09 },
            },
          },
        ],
      },
    ],
    aorticValveInValve: [
      {
        name: "CoreValve",
        manufacturer: "Medtronic",
        sizes: [
          {
            size: "All sizes",
            measurements: {
              peakGradient: { value: 23.48, sd: 12.1 },
              meanGradient: { value: 12.89, sd: 0.2 },
              meanEOA: { value: 1.62, sd: 0.14 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
        ],
      },
      {
        name: "Evolut",
        manufacturer: "Medtronic",
        sizes: [
          {
            size: "All sizes",
            measurements: {
              peakGradient: { value: 22.43, sd: 5.72 },
              meanGradient: { value: 14.7, sd: 9.11 },
              meanEOA: { value: 1.36, sd: 0.07 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
        ],
      },
      {
        name: "Sapien 3",
        manufacturer: "Edwards",
        sizes: [
          {
            size: "All sizes",
            measurements: {
              peakGradient: { value: 33.93, sd: 10.11 },
              meanGradient: { value: 27.0, sd: 10.2 },
              meanEOA: { value: 1.07, sd: 0.32 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
        ],
      },
      {
        name: "Sapien XT",
        manufacturer: "Edwards",
        sizes: [
          {
            size: "All sizes",
            measurements: {
              peakGradient: { value: 31.31, sd: 3.75 },
              meanGradient: { value: 18.02, sd: 4.22 },
              meanEOA: { value: 1.31, sd: 0.25 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
        ],
      },
    ],
    mitral: [
      // Mitral transcatheter valves
    ],
  },
  mechanical: {
    aortic: [
      // Mechanical aortic valves
    ],
    mitral: [
      // Mechanical mitral valves
    ],
  },
  bioprosthetic: {
    aortic: [
      // Bioprosthetic aortic valves
    ],
    mitral: [
      // Bioprosthetic mitral valves
    ],
  },
  surgical: {
    aortic: [
      {
        name: "Epic",
        manufacturer: "Abbott",
        otherInfo: "Stented porcine",
        sizes: [
          {
            size: "21 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 19.1, sd: 8.2 },
              meanEOA: { value: 1.0, sd: 0.3 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "23 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 13.9, sd: 6.0 },
              meanEOA: { value: 1.4, sd: 0.5 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "25 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 12.1, sd: 5.1 },
              meanEOA: { value: 1.5, sd: 0.5 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "27 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 11.4, sd: 4.1 },
              meanEOA: { value: 1.6, sd: 0.4 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "29 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 7.5, sd: 3.3 },
              meanEOA: { value: 2.4, sd: 1.1 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
        ],
      },
      {
        name: "Trifecta",
        manufacturer: "Abbott",
        otherInfo: "Stented bovine pericardial",
        sizes: [
          {
            size: "19 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 10.7, sd: 4.6 },
              meanEOA: { value: 1.41, sd: 0.24 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "21 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 8.1, sd: 3.5 },
              meanEOA: { value: 1.63, sd: 0.29 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "23 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 7.2, sd: 2.8 },
              meanEOA: { value: 1.81, sd: 0.3 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "25 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 6.2, sd: 2.7 },
              meanEOA: { value: 2.02, sd: 0.32 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "27 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 4.8, sd: 2.0 },
              meanEOA: { value: 2.2, sd: 0.2 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "29 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 4.7, sd: 1.6 },
              meanEOA: { value: 2.35, sd: 0.22 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
        ],
      },
      {
        name: "Trilogy",
        manufacturer: "Arbor Surgical",
        otherInfo: "Stented",
        sizes: [
          {
            size: "21 mm",
            measurements: {
              peakGradient: { value: 21, sd: 8 },
              meanGradient: { value: 11, sd: 6 },
              meanEOA: { value: 1.9, sd: 0.2 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "23 mm",
            measurements: {
              peakGradient: { value: 15, sd: 7 },
              meanGradient: { value: 8, sd: 4 },
              meanEOA: { value: 2.0, sd: 0.3 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
        ],
      },
      {
        name: "ATS Bileaflet",
        manufacturer: "Medtronic",
        otherInfo: "Bileaflet mechanical",
        sizes: [
          {
            size: "19 mm",
            measurements: {
              peakGradient: { value: 47.0, sd: 12.6 },
              meanGradient: { value: 25.3, sd: 8.0 },
              meanEOA: { value: 1.1, sd: 0.3 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "21 mm",
            measurements: {
              peakGradient: { value: 23.7, sd: 6.8 },
              meanGradient: { value: 15.9, sd: 5.0 },
              meanEOA: { value: 1.4, sd: 0.5 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "23 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 14.4, sd: 4.9 },
              meanEOA: { value: 1.7, sd: 0.5 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "25 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 11.3, sd: 3.7 },
              meanEOA: { value: 2.1, sd: 0.7 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "27 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 8.4, sd: 3.7 },
              meanEOA: { value: 2.5, sd: 0.1 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "29 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 8.0, sd: 3.0 },
              meanEOA: { value: 3.1, sd: 0.8 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
        ],
      },
      {
        name: "ATS AP Bileaflet",
        manufacturer: "Medtronic",
        otherInfo: "Bileaflet mechanical",
        sizes: [
          {
            size: "18 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 21.0, sd: 1.8 },
              meanEOA: { value: 1.2, sd: 0.3 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "20 mm",
            measurements: {
              peakGradient: { value: 21.4, sd: 4.2 },
              meanGradient: { value: 11.1, sd: 3.5 },
              meanEOA: { value: 1.3, sd: 0.3 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "22 mm",
            measurements: {
              peakGradient: { value: 18.7, sd: 8.3 },
              meanGradient: { value: 10.5, sd: 4.5 },
              meanEOA: { value: 1.7, sd: 0.4 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "24 mm",
            measurements: {
              peakGradient: { value: 15.1, sd: 5.6 },
              meanGradient: { value: 7.5, sd: 3.1 },
              meanEOA: { value: 2.0, sd: 0.6 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "26 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 6.0, sd: 2.0 },
              meanEOA: { value: 2.1, sd: 0.4 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
        ],
      },
      {
        name: "3F Enable",
        manufacturer: "ATS",
        otherInfo: "Stented",
        sizes: [
          {
            size: "21 mm",
            measurements: {
              peakGradient: { value: 27.0, sd: 8.4 },
              meanGradient: { value: 15.0, sd: 4.6 },
              meanEOA: { value: 1.1, sd: 0.4 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "23 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 13.9, sd: 6.0 },
              meanEOA: { value: 1.4, sd: 0.5 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "25 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 12.1, sd: 5.1 },
              meanEOA: { value: 1.5, sd: 0.5 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "27 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 11.4, sd: 4.1 },
              meanEOA: { value: 1.6, sd: 0.4 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "29 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 7.5, sd: 3.3 },
              meanEOA: { value: 2.4, sd: 1.1 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
        ],
      },
      {
        name: "Perimount",
        manufacturer: "Baxter",
        otherInfo: "Stented bovine pericardial",
        sizes: [
          {
            size: "19 mm",
            measurements: {
              peakGradient: { value: 32.5, sd: 8.5 },
              meanGradient: { value: 19.5, sd: 5.5 },
              meanEOA: { value: 1.3, sd: 0.2 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "21 mm",
            measurements: {
              peakGradient: { value: 24.9, sd: 7.7 },
              meanGradient: { value: 13.8, sd: 4.0 },
              meanEOA: { value: 1.3, sd: 0.3 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "23 mm",
            measurements: {
              peakGradient: { value: 19.9, sd: 7.4 },
              meanGradient: { value: 11.5, sd: 3.9 },
              meanEOA: { value: 1.6, sd: 0.3 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "25 mm",
            measurements: {
              peakGradient: { value: 16.5, sd: 7.8 },
              meanGradient: { value: 10.7, sd: 3.8 },
              meanEOA: { value: 1.6, sd: 0.4 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "27 mm",
            measurements: {
              peakGradient: { value: 12.8, sd: 5.4 },
              meanGradient: { value: 4.8, sd: 2.2 },
              meanEOA: { value: 2.0, sd: 0.4 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
        ],
      },
      {
        name: "Biocor",
        manufacturer: "Biocor",
        otherInfo: "Stented porcine",
        sizes: [
          {
            size: "23 mm",
            measurements: {
              peakGradient: { value: 30.0, sd: 10.7 },
              meanGradient: { value: 20.0, sd: 6.6 },
              meanEOA: { value: 1.3, sd: 0.3 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "25 mm",
            measurements: {
              peakGradient: { value: 23.0, sd: 7.9 },
              meanGradient: { value: 16.0, sd: 5.1 },
              meanEOA: { value: 1.7, sd: 0.4 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "27 mm",
            measurements: {
              peakGradient: { value: 22.0, sd: 6.5 },
              meanGradient: { value: 15.0, sd: 3.7 },
              meanEOA: { value: 2.2, sd: 0.4 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
        ],
      },
      {
        name: "Extended Biocor",
        manufacturer: "Biocor",
        otherInfo: "Stentless",
        sizes: [
          {
            size: "19-21 mm",
            measurements: {
              peakGradient: { value: 17.5, sd: 6.5 },
              meanGradient: { value: 9.6, sd: 3.6 },
              meanEOA: { value: 1.4, sd: 0.4 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "23 mm",
            measurements: {
              peakGradient: { value: 14.7, sd: 7.3 },
              meanGradient: { value: 7.7, sd: 3.8 },
              meanEOA: { value: 1.7, sd: 0.4 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "25 mm",
            measurements: {
              peakGradient: { value: 14.0, sd: 4.3 },
              meanGradient: { value: 7.4, sd: 2.5 },
              meanEOA: { value: 1.8, sd: 0.4 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
        ],
      },
      {
        name: "Bioflo",
        manufacturer: "Bioflo",
        otherInfo: "Stented bovine pericardial",
        sizes: [
          {
            size: "19 mm",
            measurements: {
              peakGradient: { value: 37.2, sd: 8.8 },
              meanGradient: { value: 26.4, sd: 5.5 },
              meanEOA: { value: 0.7, sd: 0.1 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "21 mm",
            measurements: {
              peakGradient: { value: 28.7, sd: 6.2 },
              meanGradient: { value: 18.7, sd: 5.5 },
              meanEOA: { value: 1.1, sd: 0.1 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
        ],
      },
      {
        name: "Bjork-Shiley",
        manufacturer: "Bjork-Shiley",
        otherInfo: "Single tilting disk",
        sizes: [
          {
            size: "21 mm",
            measurements: {
              peakGradient: { value: 38.9, sd: 11.9 },
              meanGradient: { value: 21.8, sd: 3.4 },
              meanEOA: { value: 1.1, sd: 0.3 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "23 mm",
            measurements: {
              peakGradient: { value: 28.8, sd: 11.2 },
              meanGradient: { value: 15.7, sd: 5.3 },
              meanEOA: { value: 1.3, sd: 0.3 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "25 mm",
            measurements: {
              peakGradient: { value: 23.7, sd: 8.2 },
              meanGradient: { value: 13.0, sd: 5.0 },
              meanEOA: { value: 1.5, sd: 0.4 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "27 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 10.0, sd: 2.0 },
              meanEOA: { value: 1.6, sd: 0.3 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
        ],
      },
      {
        name: "Carbomedics Reduced",
        manufacturer: "Carbomedics",
        otherInfo: "Bileaflet",
        sizes: [
          {
            size: "19 mm",
            measurements: {
              peakGradient: { value: 43.4, sd: 1.2 },
              meanGradient: { value: 24.4, sd: 1.2 },
              meanEOA: { value: 1.2, sd: 0.1 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
        ],
      },
      {
        name: "Carbomedics Standard",
        manufacturer: "Carbomedics",
        otherInfo: "Bileaflet",
        sizes: [
          {
            size: "19 mm",
            measurements: {
              peakGradient: { value: 38.0, sd: 12.8 },
              meanGradient: { value: 18.9, sd: 8.3 },
              meanEOA: { value: 1.0, sd: 0.3 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "21 mm",
            measurements: {
              peakGradient: { value: 26.8, sd: 10.1 },
              meanGradient: { value: 12.9, sd: 5.4 },
              meanEOA: { value: 1.5, sd: 0.4 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "23 mm",
            measurements: {
              peakGradient: { value: 22.5, sd: 7.4 },
              meanGradient: { value: 11.0, sd: 4.6 },
              meanEOA: { value: 1.4, sd: 0.3 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "25 mm",
            measurements: {
              peakGradient: { value: 19.6, sd: 7.8 },
              meanGradient: { value: 9.1, sd: 3.5 },
              meanEOA: { value: 1.8, sd: 0.4 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "27 mm",
            measurements: {
              peakGradient: { value: 17.5, sd: 7.1 },
              meanGradient: { value: 7.9, sd: 3.2 },
              meanEOA: { value: 2.2, sd: 0.2 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "29 mm",
            measurements: {
              peakGradient: { value: 9.1, sd: 4.7 },
              meanGradient: { value: 5.6, sd: 3.0 },
              meanEOA: { value: 3.2, sd: 1.6 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
        ],
      },
      {
        name: "Carbomedics Tophat",
        manufacturer: "Carbomedics",
        otherInfo: "Bileaflet",
        sizes: [
          {
            size: "21 mm",
            measurements: {
              peakGradient: { value: 30.2, sd: 10.9 },
              meanGradient: { value: 14.9, sd: 5.4 },
              meanEOA: { value: 1.2, sd: 0.3 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "23 mm",
            measurements: {
              peakGradient: { value: 24.2, sd: 7.6 },
              meanGradient: { value: 12.5, sd: 4.4 },
              meanEOA: { value: 1.4, sd: 0.4 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "25 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 9.5, sd: 2.9 },
              meanEOA: { value: 1.6, sd: 0.32 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
        ],
      },
      {
        name: "Carpentier Edwards Pericardial",
        manufacturer: "Carpentier Edwards",
        otherInfo: "Stented bovine pericardial",
        sizes: [
          {
            size: "19 mm",
            measurements: {
              peakGradient: { value: 32.1, sd: 3.4 },
              meanGradient: { value: 24.2, sd: 8.6 },
              meanEOA: { value: 1.2, sd: 0.3 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "21 mm",
            measurements: {
              peakGradient: { value: 25.7, sd: 9.9 },
              meanGradient: { value: 20.3, sd: 9.1 },
              meanEOA: { value: 1.5, sd: 0.4 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "23 mm",
            measurements: {
              peakGradient: { value: 21.7, sd: 8.6 },
              meanGradient: { value: 13.0, sd: 5.3 },
              meanEOA: { value: 1.8, sd: 0.3 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "25 mm",
            measurements: {
              peakGradient: { value: 16.5, sd: 5.4 },
              meanGradient: { value: 9.0, sd: 2.3 },
              meanEOA: { value: "NA", sd: "NA" },
              DVI: { value: "NA", sd: "NA" },
            },
          },
        ],
      },
      {
        name: "Carpentier Edwards Standard",
        manufacturer: "Carpentier Edwards",
        otherInfo: "Stented porcine",
        sizes: [
          {
            size: "19 mm",
            measurements: {
              peakGradient: { value: 43.5, sd: 12.7 },
              meanGradient: { value: 25.6, sd: 8.0 },
              meanEOA: { value: 0.9, sd: 0.2 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "21 mm",
            measurements: {
              peakGradient: { value: 27.7, sd: 7.6 },
              meanGradient: { value: 17.3, sd: 6.2 },
              meanEOA: { value: 1.5, sd: 0.3 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "23 mm",
            measurements: {
              peakGradient: { value: 28.9, sd: 7.5 },
              meanGradient: { value: 16.1, sd: 6.2 },
              meanEOA: { value: 1.7, sd: 0.5 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "25 mm",
            measurements: {
              peakGradient: { value: 24.0, sd: 7.1 },
              meanGradient: { value: 12.9, sd: 4.6 },
              meanEOA: { value: 1.9, sd: 0.5 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "27 mm",
            measurements: {
              peakGradient: { value: 22.1, sd: 8.2 },
              meanGradient: { value: 12.1, sd: 5.5 },
              meanEOA: { value: 2.3, sd: 0.6 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "29 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 9.9, sd: 2.9 },
              meanEOA: { value: 2.8, sd: 0.5 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
        ],
      },
      {
        name: "Carpentier Supra-Annular",
        manufacturer: "Carpentier Edwards",
        otherInfo: "Stented porcine",
        sizes: [
          {
            size: "19 mm",
            measurements: {
              peakGradient: { value: 34.1, sd: 2.7 },
              meanGradient: { value: "NA", sd: "NA" },
              meanEOA: { value: 1.1, sd: 0.1 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "21 mm",
            measurements: {
              peakGradient: { value: 28.0, sd: 10.5 },
              meanGradient: { value: 17.5, sd: 3.8 },
              meanEOA: { value: 1.4, sd: 0.9 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "23 mm",
            measurements: {
              peakGradient: { value: 25.3, sd: 10.5 },
              meanGradient: { value: 13.4, sd: 4.5 },
              meanEOA: { value: 1.6, sd: 0.6 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "25 mm",
            measurements: {
              peakGradient: { value: 24.4, sd: 7.6 },
              meanGradient: { value: 13.2, sd: 4.8 },
              meanEOA: { value: 1.8, sd: 0.4 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "27 mm",
            measurements: {
              peakGradient: { value: 16.7, sd: 4.7 },
              meanGradient: { value: 8.8, sd: 2.8 },
              meanEOA: { value: 1.9, sd: 0.7 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
        ],
      },
      {
        name: "Cryolife",
        manufacturer: "Cryolife",
        otherInfo: "Stentless",
        sizes: [
          {
            size: "19 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 9.0, sd: 2.0 },
              meanEOA: { value: 1.5, sd: 0.3 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "21 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 6.6, sd: 2.9 },
              meanEOA: { value: 1.7, sd: 0.4 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "23 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 6.0, sd: 2.3 },
              meanEOA: { value: 2.3, sd: 0.2 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "25 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 6.1, sd: 2.6 },
              meanEOA: { value: 2.6, sd: 0.2 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "27 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 4.0, sd: 2.4 },
              meanEOA: { value: 2.8, sd: 0.3 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
        ],
      },
      {
        name: "Edwards Duromedics",
        manufacturer: "Edwards",
        otherInfo: "Bileaflet",
        sizes: [
          {
            size: "21 mm",
            measurements: {
              peakGradient: { value: 39.0, sd: 13.0 },
              meanGradient: { value: "NA", sd: "NA" },
              meanEOA: { value: "NA", sd: "NA" },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "23 mm",
            measurements: {
              peakGradient: { value: 32.0, sd: 8.0 },
              meanGradient: { value: "NA", sd: "NA" },
              meanEOA: { value: "NA", sd: "NA" },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "25 mm",
            measurements: {
              peakGradient: { value: 26.0, sd: 10.0 },
              meanGradient: { value: "NA", sd: "NA" },
              meanEOA: { value: "NA", sd: "NA" },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "27 mm",
            measurements: {
              peakGradient: { value: 24.0, sd: 10.0 },
              meanGradient: { value: "NA", sd: "NA" },
              meanEOA: { value: "NA", sd: "NA" },
              DVI: { value: "NA", sd: "NA" },
            },
          },
        ],
      },
      {
        name: "Inspiris Resilia",
        manufacturer: "Edwards",
        otherInfo: "Stented",
        sizes: [
          {
            size: "19 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 17.6, sd: 7.8 },
              meanEOA: { value: 1.1, sd: 0.2 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "21 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 12.6, sd: 4.7 },
              meanEOA: { value: 1.3, sd: 0.3 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "23 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 10.1, sd: 3.8 },
              meanEOA: { value: 1.6, sd: 0.4 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "25 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 9.6, sd: 5.2 },
              meanEOA: { value: 1.8, sd: 0.5 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "27 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 8.2, sd: 3.5 },
              meanEOA: { value: 2.2, sd: 0.6 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
        ],
      },
      {
        name: "Intuity",
        manufacturer: "Edwards",
        otherInfo: "Rapid deployment",
        sizes: [
          {
            size: "19 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 13.9, sd: 3.9 },
              meanEOA: { value: 1.1, sd: 0.1 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "21 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 11.6, sd: 3.6 },
              meanEOA: { value: 1.3, sd: 0.1 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "23 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 10.4, sd: 3.5 },
              meanEOA: { value: 1.7, sd: 0.2 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "25 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 9.1, sd: 3.2 },
              meanEOA: { value: 1.9, sd: 0.2 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "27 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 8.3, sd: 3.7 },
              meanEOA: { value: 2.2, sd: 0.2 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
        ],
      },
      {
        name: "Mira",
        manufacturer: "Edwards",
        otherInfo: "Bileaflet",
        sizes: [
          {
            size: "19 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 18.2, sd: 5.3 },
              meanEOA: { value: 1.2, sd: 0.4 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "21 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 13.3, sd: 4.3 },
              meanEOA: { value: 1.6, sd: 0.4 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "23 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 14.7, sd: 2.8 },
              meanEOA: { value: 1.6, sd: 0.6 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "25 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 13.1, sd: 3.8 },
              meanEOA: { value: 1.9, sd: "NA" },
              DVI: { value: "NA", sd: "NA" },
            },
          },
        ],
      },
      {
        name: "Edwards Mosaic",
        manufacturer: "Edwards",
        otherInfo: "Stented",
        sizes: [
          {
            size: "21 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 13.3, sd: 5.3 },
              meanEOA: { value: 1.4, sd: 0.4 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "23 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 11.8, sd: 4.9 },
              meanEOA: { value: 1.6, sd: 0.5 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "25 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 10.6, sd: 4.4 },
              meanEOA: { value: 1.8, sd: 0.5 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "27 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 9.1, sd: 4.0 },
              meanEOA: { value: 2.0, sd: 0.5 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "29 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 8.6, sd: 2.9 },
              meanEOA: { value: 2.3, sd: 0.6 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
        ],
      },
      {
        name: "Hancock",
        manufacturer: "Hancock",
        otherInfo: "Stented porcine",
        sizes: [
          {
            size: "21 mm",
            measurements: {
              peakGradient: { value: 18.0, sd: 6.0 },
              meanGradient: { value: 12.0, sd: 2.0 },
              meanEOA: { value: "NA", sd: "NA" },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "23 mm",
            measurements: {
              peakGradient: { value: 16.0, sd: 2.0 },
              meanGradient: { value: 11.0, sd: 2.0 },
              meanEOA: { value: "NA", sd: "NA" },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "25 mm",
            measurements: {
              peakGradient: { value: 15.0, sd: 3.0 },
              meanGradient: { value: 10.0, sd: 3.0 },
              meanEOA: { value: "NA", sd: "NA" },
              DVI: { value: "NA", sd: "NA" },
            },
          },
        ],
      },
      {
        name: "Hancock II",
        manufacturer: "Hancock",
        otherInfo: "Stented porcine",
        sizes: [
          {
            size: "21 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 14.8, sd: 4.1 },
              meanEOA: { value: 1.3, sd: 0.4 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "23 mm",
            measurements: {
              peakGradient: { value: 34.0, sd: 13.0 },
              meanGradient: { value: 16.6, sd: 8.5 },
              meanEOA: { value: 1.3, sd: 0.4 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "25 mm",
            measurements: {
              peakGradient: { value: 22.0, sd: 5.3 },
              meanGradient: { value: 10.8, sd: 2.8 },
              meanEOA: { value: 1.6, sd: 0.4 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "29 mm",
            measurements: {
              peakGradient: { value: 16.2, sd: 1.5 },
              meanGradient: { value: 8.2, sd: 1.7 },
              meanEOA: { value: 1.6, sd: 0.2 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
        ],
      },
      {
        name: "Homograft",
        manufacturer: "Homograft",
        otherInfo: "Homograft valves",
        sizes: [
          {
            size: "17-19 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 9.7, sd: 4.2 },
              meanEOA: { value: 4.2, sd: 1.8 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "19-21 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: "NA", sd: "NA" },
              meanEOA: { value: 5.4, sd: 0.9 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "20-21 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 7.9, sd: 4.0 },
              meanEOA: { value: 3.6, sd: 2.0 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "20-22 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 7.2, sd: 3.0 },
              meanEOA: { value: 3.5, sd: 1.5 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "22 mm",
            measurements: {
              peakGradient: { value: 1.7, sd: 0.3 },
              meanGradient: { value: "NA", sd: "NA" },
              meanEOA: { value: 5.8, sd: 3.2 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
        ],
      },
      {
        name: "Intact",
        manufacturer: "Medtronic",
        otherInfo: "Stented porcine",
        sizes: [
          {
            size: "19 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 19.0, sd: 7.0 },
              meanEOA: { value: 1.1, sd: 0.3 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "21 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 15.0, sd: 6.0 },
              meanEOA: { value: 1.3, sd: 0.4 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "23 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 13.0, sd: 5.0 },
              meanEOA: { value: 1.5, sd: 0.4 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "25 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 11.0, sd: 4.0 },
              meanEOA: { value: 1.8, sd: 0.5 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "27 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 9.0, sd: 3.0 },
              meanEOA: { value: 2.0, sd: 0.5 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
        ],
      },
      {
        name: "Ionescu-Shiley",
        manufacturer: "Shiley",
        otherInfo: "Stented bovine pericardial",
        sizes: [
          {
            size: "19 mm",
            measurements: {
              peakGradient: { value: 32.0, sd: 12.0 },
              meanGradient: { value: 17.0, sd: 7.0 },
              meanEOA: { value: 1.2, sd: 0.3 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "21 mm",
            measurements: {
              peakGradient: { value: 26.0, sd: 10.0 },
              meanGradient: { value: 14.0, sd: 6.0 },
              meanEOA: { value: 1.4, sd: 0.4 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "23 mm",
            measurements: {
              peakGradient: { value: 22.0, sd: 8.0 },
              meanGradient: { value: 12.0, sd: 5.0 },
              meanEOA: { value: 1.7, sd: 0.4 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "25 mm",
            measurements: {
              peakGradient: { value: 18.0, sd: 7.0 },
              meanGradient: { value: 10.0, sd: 4.0 },
              meanEOA: { value: 1.9, sd: 0.5 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
        ],
      },
      {
        name: "Labcor Santiago",
        manufacturer: "Labcor",
        otherInfo: "Stented porcine",
        sizes: [
          {
            size: "19 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 15.0, sd: 6.0 },
              meanEOA: { value: 1.2, sd: 0.3 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "21 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 12.0, sd: 5.0 },
              meanEOA: { value: 1.4, sd: 0.4 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "23 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 10.0, sd: 4.0 },
              meanEOA: { value: 1.7, sd: 0.4 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "25 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 8.0, sd: 3.0 },
              meanEOA: { value: 1.9, sd: 0.5 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
        ],
      },
      {
        name: "Labcor Synergy",
        manufacturer: "Labcor",
        otherInfo: "Stented porcine",
        sizes: [
          {
            size: "19 mm",
            measurements: {
              peakGradient: { value: 25.0, sd: 8.0 },
              meanGradient: { value: 14.0, sd: 5.0 },
              meanEOA: { value: 1.2, sd: 0.3 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "21 mm",
            measurements: {
              peakGradient: { value: 20.0, sd: 7.0 },
              meanGradient: { value: 11.0, sd: 4.0 },
              meanEOA: { value: 1.5, sd: 0.4 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "23 mm",
            measurements: {
              peakGradient: { value: 16.0, sd: 6.0 },
              meanGradient: { value: 9.0, sd: 3.0 },
              meanEOA: { value: 1.8, sd: 0.4 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "25 mm",
            measurements: {
              peakGradient: { value: 13.0, sd: 5.0 },
              meanGradient: { value: 7.0, sd: 2.0 },
              meanEOA: { value: 2.1, sd: 0.5 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
        ],
      },
      {
        name: "MCRI On-X",
        manufacturer: "MCRI",
        otherInfo: "Bileaflet mechanical",
        sizes: [
          {
            size: "19 mm",
            measurements: {
              peakGradient: { value: 28.7, sd: 10.2 },
              meanGradient: { value: 16.5, sd: 6.9 },
              meanEOA: { value: 1.5, sd: 0.2 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "21 mm",
            measurements: {
              peakGradient: { value: 20.4, sd: 6.7 },
              meanGradient: { value: 10.3, sd: 3.7 },
              meanEOA: { value: 1.7, sd: 0.4 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "23 mm",
            measurements: {
              peakGradient: { value: 16.0, sd: 5.7 },
              meanGradient: { value: 7.8, sd: 3.2 },
              meanEOA: { value: 2.0, sd: 0.6 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "25 mm",
            measurements: {
              peakGradient: { value: 11.1, sd: 4.4 },
              meanGradient: { value: 5.2, sd: 2.3 },
              meanEOA: { value: 2.4, sd: 0.8 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
        ],
      },
      {
        name: "Medtronic Advantage",
        manufacturer: "Medtronic",
        otherInfo: "Bileaflet mechanical",
        sizes: [
          {
            size: "19 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 13.0, sd: 5.0 },
              meanEOA: { value: 1.3, sd: 0.3 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "21 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 10.0, sd: 4.0 },
              meanEOA: { value: 1.5, sd: 0.4 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "23 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 7.0, sd: 3.0 },
              meanEOA: { value: 1.8, sd: 0.4 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "25 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 6.0, sd: 2.0 },
              meanEOA: { value: 2.1, sd: 0.5 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
        ],
      },
      {
        name: "Medtronic Avalus",
        manufacturer: "Medtronic",
        otherInfo: "Stented bovine pericardial",
        sizes: [
          {
            size: "19 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 12.8, sd: 4.9 },
              meanEOA: { value: 1.2, sd: 0.3 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "21 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 11.3, sd: 4.2 },
              meanEOA: { value: 1.4, sd: 0.4 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "23 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 9.8, sd: 3.7 },
              meanEOA: { value: 1.7, sd: 0.4 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "25 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 8.3, sd: 3.1 },
              meanEOA: { value: 2.0, sd: 0.5 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "27 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 7.1, sd: 2.6 },
              meanEOA: { value: 2.3, sd: 0.6 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
        ],
      },
      {
        name: "Medtronic Freestyle",
        manufacturer: "Medtronic",
        otherInfo: "Stentless porcine",
        sizes: [
          {
            size: "19 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 7.0, sd: 4.0 },
              meanEOA: { value: 1.4, sd: 0.4 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "21 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 6.0, sd: 3.0 },
              meanEOA: { value: 1.6, sd: 0.4 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "23 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 5.0, sd: 2.0 },
              meanEOA: { value: 1.8, sd: 0.5 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "25 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 4.0, sd: 2.0 },
              meanEOA: { value: 2.0, sd: 0.5 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "27 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 3.0, sd: 1.0 },
              meanEOA: { value: 2.3, sd: 0.6 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
        ],
      },
      {
        name: "Medtronic-Hall",
        manufacturer: "Medtronic",
        otherInfo: "Single tilting disk",
        sizes: [
          {
            size: "20 mm",
            measurements: {
              peakGradient: { value: 26.0, sd: 8.0 },
              meanGradient: { value: 13.0, sd: 4.0 },
              meanEOA: { value: 1.4, sd: 0.4 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "23 mm",
            measurements: {
              peakGradient: { value: 18.0, sd: 6.0 },
              meanGradient: { value: 9.0, sd: 3.0 },
              meanEOA: { value: 1.8, sd: 0.5 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "27 mm",
            measurements: {
              peakGradient: { value: 12.0, sd: 4.0 },
              meanGradient: { value: 6.0, sd: 2.0 },
              meanEOA: { value: 2.5, sd: 0.6 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
        ],
      },
      {
        name: "Monostrut Bjork-Shiley",
        manufacturer: "Bjork-Shiley",
        otherInfo: "Single tilting disk",
        sizes: [
          {
            size: "21 mm",
            measurements: {
              peakGradient: { value: 35.0, sd: 12.0 },
              meanGradient: { value: 19.0, sd: 7.0 },
              meanEOA: { value: 1.2, sd: 0.3 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "23 mm",
            measurements: {
              peakGradient: { value: 28.0, sd: 10.0 },
              meanGradient: { value: 15.0, sd: 6.0 },
              meanEOA: { value: 1.5, sd: 0.4 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "25 mm",
            measurements: {
              peakGradient: { value: 23.0, sd: 8.0 },
              meanGradient: { value: 12.0, sd: 5.0 },
              meanEOA: { value: 1.8, sd: 0.5 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "27 mm",
            measurements: {
              peakGradient: { value: 19.0, sd: 7.0 },
              meanGradient: { value: 10.0, sd: 4.0 },
              meanEOA: { value: 2.1, sd: 0.5 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
        ],
      },
      {
        name: "Prima",
        manufacturer: "Edwards",
        otherInfo: "Stented porcine",
        sizes: [
          {
            size: "21 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 16.0, sd: 6.0 },
              meanEOA: { value: 1.3, sd: 0.3 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "23 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 13.0, sd: 5.0 },
              meanEOA: { value: 1.6, sd: 0.4 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "25 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 11.0, sd: 4.0 },
              meanEOA: { value: 1.8, sd: 0.5 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
        ],
      },
      {
        name: "Omnicarbon",
        manufacturer: "Medical Inc",
        otherInfo: "Tilting disk",
        sizes: [
          {
            size: "21 mm",
            measurements: {
              peakGradient: { value: 24.0, sd: 8.0 },
              meanGradient: { value: 13.0, sd: 5.0 },
              meanEOA: { value: 1.4, sd: 0.3 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "23 mm",
            measurements: {
              peakGradient: { value: 19.0, sd: 7.0 },
              meanGradient: { value: 10.0, sd: 4.0 },
              meanEOA: { value: 1.7, sd: 0.4 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "25 mm",
            measurements: {
              peakGradient: { value: 15.0, sd: 6.0 },
              meanGradient: { value: 8.0, sd: 3.0 },
              meanEOA: { value: 2.0, sd: 0.5 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
        ],
      },
      {
        name: "Omniscience",
        manufacturer: "Medical Inc",
        otherInfo: "Tilting disk",
        sizes: [
          {
            size: "21 mm",
            measurements: {
              peakGradient: { value: 28.0, sd: 9.0 },
              meanGradient: { value: 15.0, sd: 5.0 },
              meanEOA: { value: 1.3, sd: 0.3 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "23 mm",
            measurements: {
              peakGradient: { value: 22.0, sd: 8.0 },
              meanGradient: { value: 12.0, sd: 4.0 },
              meanEOA: { value: 1.6, sd: 0.4 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "25 mm",
            measurements: {
              peakGradient: { value: 18.0, sd: 7.0 },
              meanGradient: { value: 9.0, sd: 3.0 },
              meanEOA: { value: 1.9, sd: 0.5 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
        ],
      },
      {
        name: "Starr-Edwards",
        manufacturer: "Edwards",
        otherInfo: "Caged ball",
        sizes: [
          {
            size: "21 mm",
            measurements: {
              peakGradient: { value: 45.0, sd: 15.0 },
              meanGradient: { value: 25.0, sd: 8.0 },
              meanEOA: { value: 1.0, sd: 0.2 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "23 mm",
            measurements: {
              peakGradient: { value: 38.0, sd: 12.0 },
              meanGradient: { value: 20.0, sd: 7.0 },
              meanEOA: { value: 1.2, sd: 0.3 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "25 mm",
            measurements: {
              peakGradient: { value: 32.0, sd: 10.0 },
              meanGradient: { value: 17.0, sd: 6.0 },
              meanEOA: { value: 1.4, sd: 0.4 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
        ],
      },
      {
        name: "Sorin Bicarbon",
        manufacturer: "Sorin",
        otherInfo: "Bileaflet mechanical",
        sizes: [
          {
            size: "19 mm",
            measurements: {
              peakGradient: { value: 22.7, sd: 7.8 },
              meanGradient: { value: 11.8, sd: 4.2 },
              meanEOA: { value: 1.3, sd: 0.3 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "21 mm",
            measurements: {
              peakGradient: { value: 17.9, sd: 6.2 },
              meanGradient: { value: 9.3, sd: 3.3 },
              meanEOA: { value: 1.6, sd: 0.4 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "23 mm",
            measurements: {
              peakGradient: { value: 14.2, sd: 4.9 },
              meanGradient: { value: 7.4, sd: 2.6 },
              meanEOA: { value: 1.9, sd: 0.5 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "25 mm",
            measurements: {
              peakGradient: { value: 11.3, sd: 3.9 },
              meanGradient: { value: 5.9, sd: 2.1 },
              meanEOA: { value: 2.2, sd: 0.6 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
        ],
      },
      {
        name: "Sorin Pericarbon Stentless",
        manufacturer: "Sorin",
        otherInfo: "Stentless bovine pericardial",
        sizes: [
          {
            size: "21 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 8.0, sd: 3.0 },
              meanEOA: { value: 1.6, sd: 0.4 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "23 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 6.0, sd: 2.0 },
              meanEOA: { value: 1.9, sd: 0.5 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "25 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 5.0, sd: 2.0 },
              meanEOA: { value: 2.2, sd: 0.6 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
        ],
      },
      {
        name: "Sorin Perceval Sutureless",
        manufacturer: "Sorin",
        otherInfo: "Sutureless bovine pericardial",
        sizes: [
          {
            size: "S",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 13.8, sd: 5.2 },
              meanEOA: { value: 1.3, sd: 0.3 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "M",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 11.2, sd: 4.1 },
              meanEOA: { value: 1.6, sd: 0.4 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "L",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 9.5, sd: 3.6 },
              meanEOA: { value: 1.9, sd: 0.5 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "XL",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 7.8, sd: 3.1 },
              meanEOA: { value: 2.2, sd: 0.6 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
        ],
      },
      {
        name: "St. Jude Medical Standard",
        manufacturer: "St. Jude Medical",
        otherInfo: "Bileaflet mechanical",
        sizes: [
          {
            size: "19 mm",
            measurements: {
              peakGradient: { value: 23.9, sd: 8.4 },
              meanGradient: { value: 12.8, sd: 4.5 },
              meanEOA: { value: 1.0, sd: 0.3 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "21 mm",
            measurements: {
              peakGradient: { value: 19.2, sd: 7.1 },
              meanGradient: { value: 10.3, sd: 3.8 },
              meanEOA: { value: 1.4, sd: 0.4 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "23 mm",
            measurements: {
              peakGradient: { value: 15.6, sd: 5.9 },
              meanGradient: { value: 8.4, sd: 3.2 },
              meanEOA: { value: 1.7, sd: 0.4 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "25 mm",
            measurements: {
              peakGradient: { value: 12.8, sd: 4.8 },
              meanGradient: { value: 6.9, sd: 2.6 },
              meanEOA: { value: 2.1, sd: 0.5 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "27 mm",
            measurements: {
              peakGradient: { value: 10.7, sd: 4.0 },
              meanGradient: { value: 5.7, sd: 2.2 },
              meanEOA: { value: 2.5, sd: 0.6 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
        ],
      },
      {
        name: "St. Jude Medical Regent",
        manufacturer: "St. Jude Medical",
        otherInfo: "Bileaflet mechanical",
        sizes: [
          {
            size: "17 mm",
            measurements: {
              peakGradient: { value: 28.5, sd: 10.1 },
              meanGradient: { value: 15.3, sd: 5.4 },
              meanEOA: { value: 1.0, sd: 0.3 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "19 mm",
            measurements: {
              peakGradient: { value: 22.8, sd: 8.5 },
              meanGradient: { value: 12.2, sd: 4.6 },
              meanEOA: { value: 1.3, sd: 0.3 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "21 mm",
            measurements: {
              peakGradient: { value: 18.3, sd: 7.1 },
              meanGradient: { value: 9.8, sd: 3.8 },
              meanEOA: { value: 1.6, sd: 0.4 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "23 mm",
            measurements: {
              peakGradient: { value: 14.9, sd: 5.8 },
              meanGradient: { value: 8.0, sd: 3.1 },
              meanEOA: { value: 2.0, sd: 0.5 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "25 mm",
            measurements: {
              peakGradient: { value: 12.2, sd: 4.7 },
              meanGradient: { value: 6.5, sd: 2.5 },
              meanEOA: { value: 2.4, sd: 0.6 },
              DVI: { value: "NA", sd: "NA" },
            },
          },
        ],
      },
      {
        name: "Carpentier-Edwards Perimount",
        manufacturer: "Edwards Lifesciences",
        otherInfo: "Stented pericardial bioprosthesis",
        sizes: [
          {
            size: "25 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 4.0, sd: 1.0 },
              peakVelocity: { value: 1.7, sd: 0.1 },
              pht: { value: 67, sd: 21.5 },
              meanEOA: { value: 1.75, sd: 0.53 },
            },
          },
          {
            size: "27 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 6.3, sd: 1.65 },
              peakVelocity: { value: 1.7, sd: 0.27 },
              pht: { value: 74, sd: 20.6 },
              meanEOA: { value: 1.88, sd: 0.52 },
            },
          },
          {
            size: "29 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 6.0, sd: 1.41 },
              peakVelocity: { value: 1.8, sd: 0.19 },
              pht: { value: 76, sd: 17.9 },
              meanEOA: { value: 2.02, sd: 0.57 },
            },
          },
          {
            size: "31 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 5.5, sd: 1.06 },
              peakVelocity: { value: 1.8, sd: 0.2 },
              pht: { value: 80, sd: 21.8 },
              meanEOA: { value: 2.09, sd: 0.48 },
            },
          },
          {
            size: "33 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 6.1, sd: 1.86 },
              peakVelocity: { value: 1.7, sd: 0.23 },
              pht: { value: 77, sd: 13.2 },
              meanEOA: { value: 2.24, sd: 0.97 },
            },
          },
        ],
      },
    ],
    mitral: [
      {
        name: "Abbott Epic",
        manufacturer: "Abbott",
        otherInfo: "Bioprosthetic",
        sizes: [
          {
            size: "27 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 6.1, sd: 2.9 },
              peakVelocity: { value: "NA", sd: "NA" },
              pht: { value: "NA", sd: "NA" },
              meanEOA: { value: 1.4, sd: 0.7 },
            },
          },
          {
            size: "29 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 5.5, sd: 1.7 },
              peakVelocity: { value: "NA", sd: "NA" },
              pht: { value: "NA", sd: "NA" },
              meanEOA: { value: 1.5, sd: 0.5 },
            },
          },
          {
            size: "31 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 4.8, sd: 1.4 },
              peakVelocity: { value: "NA", sd: "NA" },
              pht: { value: "NA", sd: "NA" },
              meanEOA: { value: 1.6, sd: 0.3 },
            },
          },
          {
            size: "33 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 4.1, sd: 1.6 },
              peakVelocity: { value: "NA", sd: "NA" },
              pht: { value: "NA", sd: "NA" },
              meanEOA: { value: 1.5, sd: 0.3 },
            },
          },
        ],
      },
      {
        name: "Biocor",
        manufacturer: "Biocor",
        otherInfo: "Stentless bioprosthesis",
        sizes: [
          {
            size: "27 mm",
            measurements: {
              peakGradient: { value: 13.0, sd: 1.0 },
              meanGradient: { value: "NA", sd: "NA" },
              peakVelocity: { value: "NA", sd: "NA" },
              pht: { value: "NA", sd: "NA" },
              meanEOA: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "29 mm",
            measurements: {
              peakGradient: { value: 14.0, sd: 2.5 },
              meanGradient: { value: "NA", sd: "NA" },
              peakVelocity: { value: "NA", sd: "NA" },
              pht: { value: "NA", sd: "NA" },
              meanEOA: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "31 mm",
            measurements: {
              peakGradient: { value: 11.5, sd: 0.5 },
              meanGradient: { value: "NA", sd: "NA" },
              peakVelocity: { value: "NA", sd: "NA" },
              pht: { value: "NA", sd: "NA" },
              meanEOA: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "33 mm",
            measurements: {
              peakGradient: { value: 12.0, sd: 0.5 },
              meanGradient: { value: "NA", sd: "NA" },
              peakVelocity: { value: "NA", sd: "NA" },
              pht: { value: "NA", sd: "NA" },
              meanEOA: { value: "NA", sd: "NA" },
            },
          },
        ],
      },
      {
        name: "Bioflo",
        manufacturer: "Bioflo",
        otherInfo: "Stented pericardial bioprosthesis",
        sizes: [
          {
            size: "25 mm",
            measurements: {
              peakGradient: { value: 10.0, sd: 2.0 },
              meanGradient: { value: 6.3, sd: 1.5 },
              peakVelocity: { value: "NA", sd: "NA" },
              pht: { value: "NA", sd: "NA" },
              meanEOA: { value: 2.0, sd: 0.1 },
            },
          },
          {
            size: "27 mm",
            measurements: {
              peakGradient: { value: 9.5, sd: 2.6 },
              meanGradient: { value: 5.4, sd: 1.2 },
              peakVelocity: { value: "NA", sd: "NA" },
              pht: { value: "NA", sd: "NA" },
              meanEOA: { value: 2.0, sd: 0.3 },
            },
          },
          {
            size: "29 mm",
            measurements: {
              peakGradient: { value: 5.0, sd: 2.8 },
              meanGradient: { value: 3.6, sd: 1.0 },
              peakVelocity: { value: "NA", sd: "NA" },
              pht: { value: "NA", sd: "NA" },
              meanEOA: { value: 2.4, sd: 0.2 },
            },
          },
          {
            size: "31 mm",
            measurements: {
              peakGradient: { value: 4.0, sd: "NA" },
              meanGradient: { value: 2.0, sd: "NA" },
              peakVelocity: { value: "NA", sd: "NA" },
              pht: { value: "NA", sd: "NA" },
              meanEOA: { value: 2.3, sd: "NA" },
            },
          },
        ],
      },
      {
        name: "Bjork-Shiley",
        manufacturer: "Bjork-Shiley",
        otherInfo: "Tilting disk",
        sizes: [
          {
            size: "23 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: "NA", sd: "NA" },
              peakVelocity: { value: 1.7, sd: "NA" },
              pht: { value: 115, sd: "NA" },
              meanEOA: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "25 mm",
            measurements: {
              peakGradient: { value: 12.0, sd: 4.0 },
              meanGradient: { value: 6.0, sd: 2.0 },
              peakVelocity: { value: 1.75, sd: 0.38 },
              pht: { value: 99, sd: 27 },
              meanEOA: { value: 1.72, sd: 0.6 },
            },
          },
          {
            size: "27 mm",
            measurements: {
              peakGradient: { value: 10.0, sd: 4.0 },
              meanGradient: { value: 5.0, sd: 2.0 },
              peakVelocity: { value: 1.6, sd: 0.49 },
              pht: { value: 89, sd: 28 },
              meanEOA: { value: 1.81, sd: 0.54 },
            },
          },
          {
            size: "29 mm",
            measurements: {
              peakGradient: { value: 7.83, sd: 2.93 },
              meanGradient: { value: 2.83, sd: 1.27 },
              peakVelocity: { value: 1.37, sd: 0.25 },
              pht: { value: 79, sd: 17 },
              meanEOA: { value: 2.1, sd: 0.43 },
            },
          },
          {
            size: "31 mm",
            measurements: {
              peakGradient: { value: 6.0, sd: 3.0 },
              meanGradient: { value: 2.0, sd: 1.9 },
              peakVelocity: { value: 1.41, sd: 0.26 },
              pht: { value: 70, sd: 14 },
              meanEOA: { value: 2.2, sd: 0.3 },
            },
          },
        ],
      },
      {
        name: "Bjork-Shiley Monostrut",
        manufacturer: "Bjork-Shiley",
        otherInfo: "Tilting disk",
        sizes: [
          {
            size: "23 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 5.0, sd: "NA" },
              peakVelocity: { value: 1.9, sd: "NA" },
              pht: { value: "NA", sd: "NA" },
              meanEOA: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "25 mm",
            measurements: {
              peakGradient: { value: 13.0, sd: 2.5 },
              meanGradient: { value: 5.57, sd: 2.3 },
              peakVelocity: { value: 1.8, sd: 0.3 },
              pht: { value: "NA", sd: "NA" },
              meanEOA: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "27 mm",
            measurements: {
              peakGradient: { value: 12.0, sd: 2.5 },
              meanGradient: { value: 4.53, sd: 2.2 },
              peakVelocity: { value: 1.7, sd: 0.4 },
              pht: { value: "NA", sd: "NA" },
              meanEOA: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "29 mm",
            measurements: {
              peakGradient: { value: 13.0, sd: 3.0 },
              meanGradient: { value: 4.26, sd: 1.6 },
              peakVelocity: { value: 1.6, sd: 0.3 },
              pht: { value: "NA", sd: "NA" },
              meanEOA: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "31 mm",
            measurements: {
              peakGradient: { value: 14.0, sd: 4.5 },
              meanGradient: { value: 4.9, sd: 1.6 },
              peakVelocity: { value: 1.7, sd: 0.3 },
              pht: { value: "NA", sd: "NA" },
              meanEOA: { value: "NA", sd: "NA" },
            },
          },
        ],
      },
      {
        name: "Carbomedics",
        manufacturer: "Carbomedics",
        otherInfo: "Bileaflet",
        sizes: [
          {
            size: "23 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: "NA", sd: "NA" },
              peakVelocity: { value: 1.9, sd: 0.1 },
              pht: { value: 126, sd: 7 },
              meanEOA: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "25 mm",
            measurements: {
              peakGradient: { value: 10.3, sd: 2.3 },
              meanGradient: { value: 3.6, sd: 0.6 },
              peakVelocity: { value: 1.3, sd: 0.1 },
              pht: { value: 93, sd: 8 },
              meanEOA: { value: 2.9, sd: 0.8 },
            },
          },
          {
            size: "27 mm",
            measurements: {
              peakGradient: { value: 8.79, sd: 3.46 },
              meanGradient: { value: 3.46, sd: 1.03 },
              peakVelocity: { value: 1.61, sd: 0.3 },
              pht: { value: 89, sd: 20 },
              meanEOA: { value: 2.9, sd: 0.75 },
            },
          },
          {
            size: "29 mm",
            measurements: {
              peakGradient: { value: 8.78, sd: 2.9 },
              meanGradient: { value: 3.39, sd: 0.97 },
              peakVelocity: { value: 1.52, sd: 0.3 },
              pht: { value: 88, sd: 17 },
              meanEOA: { value: 2.3, sd: 0.4 },
            },
          },
          {
            size: "31 mm",
            measurements: {
              peakGradient: { value: 8.87, sd: 2.34 },
              meanGradient: { value: 3.32, sd: 0.87 },
              peakVelocity: { value: 1.61, sd: 0.29 },
              pht: { value: 92, sd: 24 },
              meanEOA: { value: 2.8, sd: 1.14 },
            },
          },
          {
            size: "33 mm",
            measurements: {
              peakGradient: { value: 8.8, sd: 2.2 },
              meanGradient: { value: 4.8, sd: 2.5 },
              peakVelocity: { value: 1.5, sd: 0.2 },
              pht: { value: 93, sd: 12 },
              meanEOA: { value: "NA", sd: "NA" },
            },
          },
        ],
      },
      {
        name: "Carpentier-Edwards",
        manufacturer: "Edwards",
        otherInfo: "Stented bioprosthesis",
        sizes: [
          {
            size: "27 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 6.0, sd: 2.0 },
              peakVelocity: { value: 1.7, sd: 0.3 },
              pht: { value: 98, sd: 28 },
              meanEOA: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "29 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 4.7, sd: 2.0 },
              peakVelocity: { value: 1.76, sd: 0.27 },
              pht: { value: 92, sd: 14 },
              meanEOA: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "31 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 4.4, sd: 2.0 },
              peakVelocity: { value: 1.54, sd: 0.15 },
              pht: { value: 92, sd: 19 },
              meanEOA: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "33 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 6.0, sd: 3.0 },
              peakVelocity: { value: "NA", sd: "NA" },
              pht: { value: 93, sd: 12 },
              meanEOA: { value: "NA", sd: "NA" },
            },
          },
        ],
      },
      {
        name: "Carpentier-Edwards Pericardial",
        manufacturer: "Edwards",
        otherInfo: "Stented Bioprosthesis",
        sizes: [
          {
            size: "27 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 3.6, sd: "NA" },
              peakVelocity: { value: 1.6, sd: "NA" },
              pht: { value: 100, sd: "NA" },
              meanEOA: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "29 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 5.25, sd: 2.36 },
              peakVelocity: { value: 1.67, sd: 0.3 },
              pht: { value: 110, sd: 15 },
              meanEOA: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "31 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 4.05, sd: 0.83 },
              peakVelocity: { value: 1.53, sd: 0.1 },
              pht: { value: 90, sd: 11 },
              meanEOA: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "33 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 1.0, sd: "NA" },
              peakVelocity: { value: 0.8, sd: "NA" },
              pht: { value: 80, sd: "NA" },
              meanEOA: { value: "NA", sd: "NA" },
            },
          },
        ],
      },
      {
        name: "Carpentier-Edwards Perimount",
        manufacturer: "Edwards",
        otherInfo: "Stented pericardial",
        sizes: [
          {
            size: "25 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 4.0, sd: 1.0 },
              peakVelocity: { value: 1.7, sd: 0.1 },
              pht: { value: 67, sd: 21.5 },
              meanEOA: { value: 1.75, sd: 0.53 },
            },
          },
          {
            size: "27 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 6.3, sd: 1.65 },
              peakVelocity: { value: 1.7, sd: 0.27 },
              pht: { value: 74, sd: 20.6 },
              meanEOA: { value: 1.88, sd: 0.52 },
            },
          },
          {
            size: "29 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 6.0, sd: 1.41 },
              peakVelocity: { value: 1.8, sd: 0.19 },
              pht: { value: 76, sd: 17.9 },
              meanEOA: { value: 2.02, sd: 0.57 },
            },
          },
          {
            size: "31 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 5.5, sd: 1.06 },
              peakVelocity: { value: 1.8, sd: 0.2 },
              pht: { value: 80, sd: 21.8 },
              meanEOA: { value: 2.09, sd: 0.48 },
            },
          },
          {
            size: "33 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 6.1, sd: 1.86 },
              peakVelocity: { value: 1.7, sd: 0.23 },
              pht: { value: 77, sd: 13.2 },
              meanEOA: { value: 2.24, sd: 0.97 },
            },
          },
        ],
      },
      {
        name: "Duromedics",
        manufacturer: "Duromedics",
        otherInfo: "Bileaflet",
        sizes: [
          {
            size: "27 mm",
            measurements: {
              peakGradient: { value: 13.0, sd: 6.0 },
              meanGradient: { value: 5.0, sd: 3.0 },
              peakVelocity: { value: 1.61, sd: 0.4 },
              pht: { value: 75, sd: 12 },
              meanEOA: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "29 mm",
            measurements: {
              peakGradient: { value: 10.0, sd: 4.0 },
              meanGradient: { value: 3.0, sd: 1.0 },
              peakVelocity: { value: 1.4, sd: 0.25 },
              pht: { value: 85, sd: 22 },
              meanEOA: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "31 mm",
            measurements: {
              peakGradient: { value: 10.5, sd: 4.33 },
              meanGradient: { value: 3.3, sd: 1.36 },
              peakVelocity: { value: 1.38, sd: 0.27 },
              pht: { value: 81, sd: 12 },
              meanEOA: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "33 mm",
            measurements: {
              peakGradient: { value: 11.2, sd: "NA" },
              meanGradient: { value: 2.5, sd: "NA" },
              peakVelocity: { value: "NA", sd: "NA" },
              pht: { value: 85, sd: "NA" },
              meanEOA: { value: "NA", sd: "NA" },
            },
          },
        ],
      },
      {
        name: "Edwards Mitris",
        manufacturer: "Edwards",
        otherInfo: "",
        sizes: [
          {
            size: "25 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 4.9, sd: 1.2 },
              peakVelocity: { value: "NA", sd: "NA" },
              pht: { value: "NA", sd: "NA" },
              meanEOA: { value: 1.1, sd: 0.4 },
            },
          },
          {
            size: "27 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 4.1, sd: 1.4 },
              peakVelocity: { value: "NA", sd: "NA" },
              pht: { value: "NA", sd: "NA" },
              meanEOA: { value: 1.2, sd: 0.3 },
            },
          },
          {
            size: "29 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 4.1, sd: 1.5 },
              peakVelocity: { value: "NA", sd: "NA" },
              pht: { value: "NA", sd: "NA" },
              meanEOA: { value: 1.5, sd: 0.6 },
            },
          },
          {
            size: "31 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 3.9, sd: 2.0 },
              peakVelocity: { value: "NA", sd: "NA" },
              pht: { value: "NA", sd: "NA" },
              meanEOA: { value: 1.4, sd: 0.5 },
            },
          },
          {
            size: "33 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 3.3, sd: 1.4 },
              peakVelocity: { value: "NA", sd: "NA" },
              pht: { value: "NA", sd: "NA" },
              meanEOA: { value: 1.5, sd: 0.7 },
            },
          },
        ],
      },
      {
        name: "Hancock I",
        manufacturer: "Hancock",
        otherInfo: "Stented bioprosthesis",
        sizes: [
          {
            size: "27 mm",
            measurements: {
              peakGradient: { value: 10.0, sd: 4.0 },
              meanGradient: { value: 5.0, sd: 2.0 },
              peakVelocity: { value: "NA", sd: "NA" },
              pht: { value: "NA", sd: "NA" },
              meanEOA: { value: 1.3, sd: 0.8 },
            },
          },
          {
            size: "29 mm",
            measurements: {
              peakGradient: { value: 7.0, sd: 3.0 },
              meanGradient: { value: 2.46, sd: 0.79 },
              peakVelocity: { value: "NA", sd: "NA" },
              pht: { value: 115, sd: 20 },
              meanEOA: { value: 1.5, sd: 0.2 },
            },
          },
          {
            size: "31 mm",
            measurements: {
              peakGradient: { value: 4.0, sd: 0.86 },
              meanGradient: { value: 4.86, sd: 1.69 },
              peakVelocity: { value: "NA", sd: "NA" },
              pht: { value: 95, sd: 17 },
              meanEOA: { value: 1.6, sd: 0.2 },
            },
          },
          {
            size: "33 mm",
            measurements: {
              peakGradient: { value: 3.0, sd: 2.0 },
              meanGradient: { value: 3.87, sd: 2.0 },
              peakVelocity: { value: "NA", sd: "NA" },
              pht: { value: 90, sd: 12 },
              meanEOA: { value: 1.9, sd: 0.2 },
            },
          },
        ],
      },
      {
        name: "Hancock II",
        manufacturer: "Hancock",
        otherInfo: "Stented bioprosthesis",
        sizes: [
          {
            size: "25 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 8.3, sd: 1.71 },
              peakVelocity: { value: 2.1, sd: 0.28 },
              pht: { value: 76, sd: 19.8 },
              meanEOA: { value: 1.42, sd: 0.29 },
            },
          },
          {
            size: "27 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 6.1, sd: 1.33 },
              peakVelocity: { value: 2.0, sd: 0.28 },
              pht: { value: 81, sd: 18.9 },
              meanEOA: { value: 1.62, sd: 0.47 },
            },
          },
          {
            size: "29 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 6.7, sd: 2.2 },
              peakVelocity: { value: 2.0, sd: 0.31 },
              pht: { value: 77, sd: 15.1 },
              meanEOA: { value: 1.83, sd: 0.68 },
            },
          },
          {
            size: "31 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 6.0, sd: 1.58 },
              peakVelocity: { value: 2.0, sd: 0.32 },
              pht: { value: 76, sd: 12.1 },
              meanEOA: { value: 1.7, sd: 0.41 },
            },
          },
          {
            size: "33 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 5.5, sd: 1.64 },
              peakVelocity: { value: 1.9, sd: 0.5 },
              pht: { value: 65, sd: 8.7 },
              meanEOA: { value: 2.71, sd: 0.77 },
            },
          },
        ],
      },
      {
        name: "Hancock Pericardial",
        manufacturer: "Hancock",
        otherInfo: "Stented bioprosthesis",
        sizes: [
          {
            size: "29 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 2.61, sd: 1.39 },
              peakVelocity: { value: 1.42, sd: 0.14 },
              pht: { value: 105, sd: 36 },
              meanEOA: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "31 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 3.57, sd: 1.02 },
              peakVelocity: { value: 1.51, sd: 0.27 },
              pht: { value: 81, sd: 23 },
              meanEOA: { value: "NA", sd: "NA" },
            },
          },
        ],
      },
      {
        name: "Ionescu-Shiley",
        manufacturer: "Shiley",
        otherInfo: "Stented bioprosthesis",
        sizes: [
          {
            size: "25 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 4.87, sd: 1.08 },
              peakVelocity: { value: 1.43, sd: 0.15 },
              pht: { value: 93, sd: 11 },
              meanEOA: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "27 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 3.21, sd: 0.82 },
              peakVelocity: { value: 1.31, sd: 0.24 },
              pht: { value: 100, sd: 28 },
              meanEOA: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "29 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 3.22, sd: 0.57 },
              peakVelocity: { value: 1.38, sd: 0.2 },
              pht: { value: 85, sd: 8 },
              meanEOA: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "31 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 3.63, sd: 0.9 },
              peakVelocity: { value: 1.45, sd: 0.06 },
              pht: { value: 100, sd: 36 },
              meanEOA: { value: "NA", sd: "NA" },
            },
          },
        ],
      },
      {
        name: "Ionescu-Shiley Low Profile",
        manufacturer: "Shiley",
        otherInfo: "Stented bioprosthesis",
        sizes: [
          {
            size: "29 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 3.31, sd: 0.96 },
              peakVelocity: { value: 1.36, sd: 0.25 },
              pht: { value: 80, sd: 30 },
              meanEOA: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "31 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 2.74, sd: 0.37 },
              peakVelocity: { value: 1.33, sd: 0.14 },
              pht: { value: 79, sd: 15 },
              meanEOA: { value: "NA", sd: "NA" },
            },
          },
        ],
      },
      {
        name: "Lillehei-Kaster",
        manufacturer: "Lillehei-Kaster",
        otherInfo: "Tilting disk",
        sizes: [
          {
            size: "18 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: "NA", sd: "NA" },
              peakVelocity: { value: 1.7, sd: "NA" },
              pht: { value: 140, sd: "NA" },
              meanEOA: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "20 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: "NA", sd: "NA" },
              peakVelocity: { value: 1.7, sd: "NA" },
              pht: { value: 67, sd: "NA" },
              meanEOA: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "22 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: "NA", sd: "NA" },
              peakVelocity: { value: 1.56, sd: 0.09 },
              pht: { value: 94, sd: 22 },
              meanEOA: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "25 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: "NA", sd: "NA" },
              peakVelocity: { value: 1.38, sd: 0.27 },
              pht: { value: 124, sd: 46 },
              meanEOA: { value: "NA", sd: "NA" },
            },
          },
        ],
      },
      {
        name: "Medtronic-Hall",
        manufacturer: "Medtronic",
        otherInfo: "Tilting disk",
        sizes: [
          {
            size: "27 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: "NA", sd: "NA" },
              peakVelocity: { value: 1.4, sd: "NA" },
              pht: { value: 78, sd: "NA" },
              meanEOA: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "29 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: "NA", sd: "NA" },
              peakVelocity: { value: 1.57, sd: 0.1 },
              pht: { value: 69, sd: 15 },
              meanEOA: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "31 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: "NA", sd: "NA" },
              peakVelocity: { value: 1.45, sd: 0.12 },
              pht: { value: 77, sd: 17 },
              meanEOA: { value: "NA", sd: "NA" },
            },
          },
        ],
      },
      {
        name: "Medtronic Intact Porcine",
        manufacturer: "Medtronic",
        otherInfo: "Stented bioprosthesis",
        sizes: [
          {
            size: "29 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 3.5, sd: 0.51 },
              peakVelocity: { value: 1.6, sd: 0.22 },
              pht: { value: "NA", sd: "NA" },
              meanEOA: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "31 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 4.2, sd: 1.44 },
              peakVelocity: { value: 1.6, sd: 0.26 },
              pht: { value: "NA", sd: "NA" },
              meanEOA: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "33 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 4.0, sd: 1.3 },
              peakVelocity: { value: 1.4, sd: 0.24 },
              pht: { value: "NA", sd: "NA" },
              meanEOA: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "35 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 3.2, sd: 1.77 },
              peakVelocity: { value: 1.3, sd: 0.5 },
              pht: { value: "NA", sd: "NA" },
              meanEOA: { value: "NA", sd: "NA" },
            },
          },
        ],
      },
      {
        name: "Medtronic Mosaic",
        manufacturer: "Medtronic",
        otherInfo: "Stented bioprosthesis",
        sizes: [
          {
            size: "25 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 8.3, sd: 1.71 },
              peakVelocity: { value: 2.1, sd: 0.28 },
              pht: { value: 76, sd: 19.8 },
              meanEOA: { value: 1.42, sd: 0.29 },
            },
          },
          {
            size: "27 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 6.1, sd: 1.33 },
              peakVelocity: { value: 2.0, sd: 0.28 },
              pht: { value: 81, sd: 18.9 },
              meanEOA: { value: 1.62, sd: 0.47 },
            },
          },
          {
            size: "29 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 6.7, sd: 2.2 },
              peakVelocity: { value: 2.0, sd: 0.31 },
              pht: { value: 77, sd: 15.1 },
              meanEOA: { value: 1.83, sd: 0.68 },
            },
          },
          {
            size: "31 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 6.0, sd: 1.58 },
              peakVelocity: { value: 2.0, sd: 0.32 },
              pht: { value: 76, sd: 12.1 },
              meanEOA: { value: 1.7, sd: 0.41 },
            },
          },
          {
            size: "33 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 5.5, sd: 1.64 },
              peakVelocity: { value: 1.9, sd: 0.5 },
              pht: { value: 65, sd: 8.7 },
              meanEOA: { value: 2.71, sd: 0.77 },
            },
          },
        ],
      },
      {
        name: "Mitroflow",
        manufacturer: "Mitroflow",
        otherInfo: "Stented bioprosthesis",
        sizes: [
          {
            size: "25 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 6.9, sd: "NA" },
              peakVelocity: { value: 2.0, sd: "NA" },
              pht: { value: 90, sd: "NA" },
              meanEOA: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "27 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 3.07, sd: 0.91 },
              peakVelocity: { value: 1.5, sd: "NA" },
              pht: { value: 90, sd: 20 },
              meanEOA: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "29 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 3.5, sd: 1.65 },
              peakVelocity: { value: 1.43, sd: 0.29 },
              pht: { value: 102, sd: 21 },
              meanEOA: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "31 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 3.85, sd: 0.81 },
              peakVelocity: { value: 1.32, sd: 0.26 },
              pht: { value: 91, sd: 22 },
              meanEOA: { value: "NA", sd: "NA" },
            },
          },
        ],
      },
      {
        name: "Omnicarbon",
        manufacturer: "Omnicarbon",
        otherInfo: "Tilting disk",
        sizes: [
          {
            size: "23 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 8.0, sd: "NA" },
              peakVelocity: { value: "NA", sd: "NA" },
              pht: { value: "NA", sd: "NA" },
              meanEOA: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "25 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 6.05, sd: 1.81 },
              peakVelocity: { value: 1.77, sd: 0.24 },
              pht: { value: 102, sd: 16 },
              meanEOA: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "27 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 4.89, sd: 2.05 },
              peakVelocity: { value: 1.63, sd: 0.36 },
              pht: { value: 105, sd: 33 },
              meanEOA: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "29 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 4.93, sd: 2.16 },
              peakVelocity: { value: 1.56, sd: 0.27 },
              pht: { value: 120, sd: 40 },
              meanEOA: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "31 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 4.18, sd: 1.4 },
              peakVelocity: { value: 1.3, sd: 0.23 },
              pht: { value: 134, sd: 31 },
              meanEOA: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "33 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 4.0, sd: 2.0 },
              peakVelocity: { value: "NA", sd: "NA" },
              pht: { value: "NA", sd: "NA" },
              meanEOA: { value: "NA", sd: "NA" },
            },
          },
        ],
      },
      {
        name: "On-X",
        manufacturer: "On-X",
        otherInfo: "Bileaflet",
        sizes: [
          {
            size: "25 mm",
            measurements: {
              peakGradient: { value: 11.0, sd: 3.0 },
              meanGradient: { value: 4.15, sd: 1.8 },
              peakVelocity: { value: 1.57, sd: 0.29 },
              pht: { value: 85, sd: 10 },
              meanEOA: { value: 1.75, sd: 0.24 },
            },
          },
          {
            size: "27/29 mm",
            measurements: {
              peakGradient: { value: 12.0, sd: 6.0 },
              meanGradient: { value: 4.46, sd: 2.22 },
              peakVelocity: { value: 1.59, sd: 0.33 },
              pht: { value: 74, sd: 13 },
              meanEOA: { value: 2.03, sd: 0.32 },
            },
          },
        ],
      },
      {
        name: "Sorin Bicarbon",
        manufacturer: "Sorin",
        otherInfo: "Bileaflet",
        sizes: [
          {
            size: "25 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 3.5, sd: 1.0 },
              peakVelocity: { value: 1.6, sd: 0.2 },
              pht: { value: 110, sd: 30 },
              meanEOA: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "27 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 3.0, sd: 1.0 },
              peakVelocity: { value: 1.5, sd: 0.2 },
              pht: { value: 100, sd: 25 },
              meanEOA: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "29 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 2.5, sd: 0.8 },
              peakVelocity: { value: 1.4, sd: 0.2 },
              pht: { value: 90, sd: 20 },
              meanEOA: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "31 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 2.0, sd: 0.5 },
              peakVelocity: { value: 1.3, sd: 0.2 },
              pht: { value: 85, sd: 15 },
              meanEOA: { value: "NA", sd: "NA" },
            },
          },
        ],
      },
      {
        name: "St. Jude Medical",
        manufacturer: "St. Jude Medical",
        otherInfo: "Bileaflet",
        sizes: [
          {
            size: "25 mm",
            measurements: {
              peakGradient: { value: 10.0, sd: 4.0 },
              meanGradient: { value: 4.0, sd: 2.0 },
              peakVelocity: { value: 1.7, sd: 0.3 },
              pht: { value: 100, sd: 25 },
              meanEOA: { value: 1.7, sd: 0.4 },
            },
          },
          {
            size: "27 mm",
            measurements: {
              peakGradient: { value: 8.0, sd: 3.0 },
              meanGradient: { value: 3.5, sd: 1.5 },
              peakVelocity: { value: 1.6, sd: 0.3 },
              pht: { value: 90, sd: 20 },
              meanEOA: { value: 1.9, sd: 0.5 },
            },
          },
          {
            size: "29 mm",
            measurements: {
              peakGradient: { value: 7.0, sd: 3.0 },
              meanGradient: { value: 3.0, sd: 1.0 },
              peakVelocity: { value: 1.5, sd: 0.2 },
              pht: { value: 85, sd: 15 },
              meanEOA: { value: 2.1, sd: 0.6 },
            },
          },
          {
            size: "31 mm",
            measurements: {
              peakGradient: { value: 6.0, sd: 2.0 },
              meanGradient: { value: 2.5, sd: 1.0 },
              peakVelocity: { value: 1.4, sd: 0.2 },
              pht: { value: 80, sd: 15 },
              meanEOA: { value: 2.3, sd: 0.7 },
            },
          },
          {
            size: "33 mm",
            measurements: {
              peakGradient: { value: 5.0, sd: 2.0 },
              meanGradient: { value: 2.0, sd: 1.0 },
              peakVelocity: { value: 1.3, sd: 0.2 },
              pht: { value: 75, sd: 15 },
              meanEOA: { value: 2.5, sd: 0.8 },
            },
          },
        ],
      },
      {
        name: "Starr-Edwards",
        manufacturer: "Edwards",
        otherInfo: "Caged ball",
        sizes: [
          {
            size: "27 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: "NA", sd: "NA" },
              peakVelocity: { value: 1.7, sd: "NA" },
              pht: { value: 140, sd: "NA" },
              meanEOA: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "29 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: "NA", sd: "NA" },
              peakVelocity: { value: 1.6, sd: "NA" },
              pht: { value: 130, sd: "NA" },
              meanEOA: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "31 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: "NA", sd: "NA" },
              peakVelocity: { value: 1.5, sd: "NA" },
              pht: { value: 120, sd: "NA" },
              meanEOA: { value: "NA", sd: "NA" },
            },
          },
        ],
      },
      {
        name: "Stentless quadrileaflet bovine pericardial",
        manufacturer: "Custom",
        otherInfo: "Stentless bioprosthesis",
        sizes: [
          {
            size: "29 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 3.0, sd: 1.0 },
              peakVelocity: { value: 1.4, sd: 0.2 },
              pht: { value: 85, sd: 15 },
              meanEOA: { value: 2.4, sd: 0.5 },
            },
          },
          {
            size: "31 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 2.5, sd: 1.0 },
              peakVelocity: { value: 1.3, sd: 0.2 },
              pht: { value: 80, sd: 15 },
              meanEOA: { value: 2.6, sd: 0.6 },
            },
          },
        ],
      },
      {
        name: "Wessex",
        manufacturer: "Wessex",
        otherInfo: "Stented bioprosthesis",
        sizes: [
          {
            size: "29 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 4.5, sd: 1.5 },
              peakVelocity: { value: 1.6, sd: 0.3 },
              pht: { value: 95, sd: 20 },
              meanEOA: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "31 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 4.0, sd: 1.5 },
              peakVelocity: { value: 1.5, sd: 0.3 },
              pht: { value: 90, sd: 20 },
              meanEOA: { value: "NA", sd: "NA" },
            },
          },
          {
            size: "33 mm",
            measurements: {
              peakGradient: { value: "NA", sd: "NA" },
              meanGradient: { value: 3.5, sd: 1.5 },
              peakVelocity: { value: 1.4, sd: 0.3 },
              pht: { value: 85, sd: 20 },
              meanEOA: { value: "NA", sd: "NA" },
            },
          },
        ],
      },
    ],
  },
};

// Helper function to flatten the data for searching
export const getAllValves = () => {
  const allValves = [];

  Object.entries(valveData).forEach(([type, positions]) => {
    Object.entries(positions).forEach(([position, valves]) => {
      valves.forEach((valve) => {
        allValves.push({
          ...valve,
          type,
          position,
        });
      });
    });
  });

  return allValves;
};

export const valveTypeLabels = {
  aortic: "Aortic",
  surgical: "Surgical",
  transcatheter: "Transcatheter",
  mitral: "Mitral",
  aorticValveInValve: "Aortic ViV",
  // ... any other existing labels
};
