// LVH threshold data for males and females
export const maleThresholds = {
  1.7: {
    95: {
      20: 12,
      25: 12,
      30: 12,
      35: 13,
      40: 13,
      45: 13,
      50: 13,
      55: 13,
      60: 14,
      65: 14,
      70: 14,
      75: 14,
      80: 15,
    },
    99.7: {
      20: 13,
      25: 14,
      30: 14,
      35: 14,
      40: 14,
      45: 14,
      50: 15,
      55: 15,
      60: 15,
      65: 15,
      70: 15,
      75: 16,
      80: 16,
    },
  },
  1.8: {
    95: {
      20: 12,
      25: 12,
      30: 13,
      35: 13,
      40: 13,
      45: 13,
      50: 13,
      55: 14,
      60: 14,
      65: 14,
      70: 14,
      75: 15,
      80: 15,
    },
    99.7: {
      20: 14,
      25: 14,
      30: 14,
      35: 14,
      40: 14,
      45: 15,
      50: 15,
      55: 15,
      60: 15,
      65: 15,
      70: 16,
      75: 16,
      80: 16,
    },
  },
  1.9: {
    95: {
      20: 13,
      25: 13,
      30: 13,
      35: 13,
      40: 13,
      45: 14,
      50: 14,
      55: 14,
      60: 14,
      65: 14,
      70: 15,
      75: 15,
      80: 15,
    },
    99.7: {
      20: 14,
      25: 14,
      30: 15,
      35: 15,
      40: 15,
      45: 15,
      50: 15,
      55: 15,
      60: 16,
      65: 16,
      70: 16,
      75: 16,
      80: 17,
    },
  },
  2.0: {
    95: {
      20: 13,
      25: 13,
      30: 13,
      35: 14,
      40: 14,
      45: 14,
      50: 14,
      55: 14,
      60: 15,
      65: 15,
      70: 15,
      75: 15,
      80: 16,
    },
    99.7: {
      20: 14,
      25: 15,
      30: 15,
      35: 15,
      40: 15,
      45: 16,
      50: 16,
      55: 16,
      60: 16,
      65: 17,
      70: 17,
      75: 17,
      80: 17,
    },
  },
  2.1: {
    95: {
      20: 13,
      25: 14,
      30: 14,
      35: 14,
      40: 14,
      45: 14,
      50: 15,
      55: 15,
      60: 15,
      65: 15,
      70: 16,
      75: 16,
      80: 16,
    },
    99.7: {
      20: 15,
      25: 15,
      30: 15,
      35: 15,
      40: 16,
      45: 16,
      50: 16,
      55: 16,
      60: 16,
      65: 17,
      70: 17,
      75: 17,
      80: 18,
    },
  },
  2.2: {
    95: {
      20: 14,
      25: 14,
      30: 14,
      35: 14,
      40: 15,
      45: 15,
      50: 15,
      55: 15,
      60: 15,
      65: 16,
      70: 16,
      75: 16,
      80: 16,
    },
    99.7: {
      20: 15,
      25: 15,
      30: 16,
      35: 16,
      40: 16,
      45: 16,
      50: 16,
      55: 17,
      60: 17,
      65: 17,
      70: 17,
      75: 18,
      80: 18,
    },
  },
  2.3: {
    95: {
      20: 14,
      25: 14,
      30: 15,
      35: 15,
      40: 15,
      45: 15,
      50: 15,
      55: 16,
      60: 16,
      65: 16,
      70: 16,
      75: 17,
      80: 17,
    },
    99.7: {
      20: 16,
      25: 16,
      30: 16,
      35: 16,
      40: 16,
      45: 16,
      50: 17,
      55: 17,
      60: 17,
      65: 17,
      70: 18,
      75: 18,
      80: 18,
    },
  },
};

export const femaleThresholds = {
  1.5: {
    95: {
      20: 10,
      25: 10,
      30: 11,
      35: 11,
      40: 11,
      45: 11,
      50: 11,
      55: 11,
      60: 12,
      65: 12,
      70: 12,
      75: 12,
      80: 13,
    },
    99.7: {
      20: 12,
      25: 12,
      30: 12,
      35: 12,
      40: 12,
      45: 13,
      50: 13,
      55: 13,
      60: 13,
      65: 13,
      70: 14,
      75: 14,
      80: 14,
    },
  },
  1.6: {
    95: {
      20: 10,
      25: 11,
      30: 11,
      35: 11,
      40: 11,
      45: 11,
      50: 12,
      55: 12,
      60: 12,
      65: 12,
      70: 12,
      75: 13,
      80: 13,
    },
    99.7: {
      20: 12,
      25: 12,
      30: 12,
      35: 12,
      40: 13,
      45: 13,
      50: 13,
      55: 13,
      60: 13,
      65: 14,
      70: 14,
      75: 14,
      80: 14,
    },
  },
  1.7: {
    95: {
      20: 11,
      25: 11,
      30: 11,
      35: 11,
      40: 11,
      45: 12,
      50: 12,
      55: 12,
      60: 12,
      65: 12,
      70: 13,
      75: 13,
      80: 13,
    },
    99.7: {
      20: 12,
      25: 12,
      30: 12,
      35: 13,
      40: 13,
      45: 13,
      50: 13,
      55: 13,
      60: 14,
      65: 14,
      70: 14,
      75: 14,
      80: 15,
    },
  },
  1.8: {
    95: {
      20: 11,
      25: 11,
      30: 11,
      35: 11,
      40: 12,
      45: 12,
      50: 12,
      55: 12,
      60: 13,
      65: 13,
      70: 13,
      75: 13,
      80: 14,
    },
    99.7: {
      20: 12,
      25: 13,
      30: 13,
      35: 13,
      40: 13,
      45: 13,
      50: 14,
      55: 14,
      60: 14,
      65: 14,
      70: 15,
      75: 15,
      80: 15,
    },
  },
  1.9: {
    95: {
      20: 11,
      25: 11,
      30: 12,
      35: 12,
      40: 12,
      45: 12,
      50: 12,
      55: 13,
      60: 13,
      65: 13,
      70: 14,
      75: 14,
      80: 14,
    },
    99.7: {
      20: 13,
      25: 13,
      30: 13,
      35: 13,
      40: 13,
      45: 14,
      50: 14,
      55: 14,
      60: 14,
      65: 15,
      70: 15,
      75: 15,
      80: 15,
    },
  },
  2.0: {
    95: {
      20: 12,
      25: 12,
      30: 12,
      35: 12,
      40: 12,
      45: 13,
      50: 13,
      55: 13,
      60: 13,
      65: 14,
      70: 14,
      75: 14,
      80: 14,
    },
    99.7: {
      20: 13,
      25: 13,
      30: 14,
      35: 14,
      40: 14,
      45: 14,
      50: 14,
      55: 15,
      60: 15,
      65: 15,
      70: 15,
      75: 15,
      80: 16,
    },
  },
  2.1: {
    95: {
      20: 12,
      25: 12,
      30: 13,
      35: 13,
      40: 13,
      45: 13,
      50: 13,
      55: 14,
      60: 14,
      65: 14,
      70: 14,
      75: 15,
      80: 15,
    },
    99.7: {
      20: 14,
      25: 14,
      30: 14,
      35: 14,
      40: 14,
      45: 15,
      50: 15,
      55: 15,
      60: 15,
      65: 15,
      70: 16,
      75: 16,
      80: 16,
    },
  },
};

// Helper function to get the nearest BSA value
export const getNearestBSA = (bsa, gender) => {
  const thresholds = gender === "male" ? maleThresholds : femaleThresholds;
  const bsaValues = Object.keys(thresholds).map(Number);
  return bsaValues.reduce((prev, curr) => {
    return Math.abs(curr - bsa) < Math.abs(prev - bsa) ? curr : prev;
  });
};

// Helper function to get the nearest age value
export const getNearestAge = (age) => {
  const ages = [20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80];
  return ages.reduce((prev, curr) => {
    return Math.abs(curr - age) < Math.abs(prev - age) ? curr : prev;
  });
};

// Function to get thresholds for given BSA, age, and gender
export const getThresholds = (bsa, age, gender) => {
  const thresholds = gender === "male" ? maleThresholds : femaleThresholds;
  const nearestBSA = getNearestBSA(bsa, gender);
  const nearestAge = getNearestAge(age);

  return {
    95: thresholds[nearestBSA]["95"][nearestAge],
    99.7: thresholds[nearestBSA]["99.7"][nearestAge],
  };
};
