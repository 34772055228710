// src/GuidelineAssistant.js
import React, { useState, useEffect } from "react";
import "./App.css"; // Ensure to style your components in this CSS file
import Menu from "./Menu"; // Import the Menu component
import Footer from "./Footer";
import { Link } from "react-router-dom"; // Assuming you're using react-router for routing

const guidelinesData = [
  {
    text: "For patients with hypertrophic cardiomyopathy or at risk for hypertrophic cardiomyopathy, shared decision-making is recommended in developing a plan of care (including, but not limited to, decisions regarding genetic evaluation, activity, lifestyle, and therapy choices) that includes a full disclosure of the risks, benefits, and anticipated outcomes of all options, as well as the opportunity for the patient and caregivers to express their goals and concerns.",
    class: "Class I",
    source: "ACC/AHA",
  },
  {
    text: "In patients with hypertrophic cardiomyopathy in whom septal reduction therapy is indicated, the procedure should be performed at experienced centers (comprehensive or primary HCM centers) with demonstrated excellence in clinical outcomes for these procedures.",
    class: "Class I",
    source: "ACC/AHA",
  },
  {
    text: "For children and adolescents with hypertrophic cardiomyopathy, a comprehensive, systematic noninvasive SCD risk assessment at initial evaluation and every 1 to 2 years thereafter is recommended and should include evaluation of these risk factors: personal history of cardiac arrest or sustained ventricular arrhythmias, personal history of syncope suspected by clinical history to be arrhythmic, family history in close relatives of premature HCM-related sudden death, cardiac arrest, or sustained ventricular arrhythmias, maximal LV wall thickness, EF, LV apical aneurysm, and NSVT episodes on continuous ambulatory electrocardiographic monitoring.",
    class: "Class I",
    source: "ACC/AHA",
  },
  {
    text: "In patients with hypertrophic cardiomyopathy who are receiving an ICD, either a single-chamber transvenous ICD or a subcutaneous ICD is recommended after a shared decision-making discussion that takes into consideration patient preferences, age, lifestyle, and potential need for pacing for bradycardia or VT termination.",
    class: "Class I",
    source: "ACC/AHA",
  },
  // Additional data here...
];

const GuidelineAssistant = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [showMenu, setShowMenu] = useState(window.innerWidth > 768);

  useEffect(() => {
    const handleResize = () => {
      setShowMenu(window.innerWidth > 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const filteredGuidelines = guidelinesData.filter((guideline) => {
    const searchWords = searchTerm.trim().split(/\s+/);
    return searchWords.every((word) =>
      guideline.text.toLowerCase().includes(word)
    );
  });

  return (
    <div className="page_div" data-component="guideline-assistant">
      {showMenu && (
        <div className="menu_div">
          <Menu />
        </div>
      )}

      <div className="content_div">
        <div className="guideline-assistant">
          <h1 className="title">Guideline Assistant</h1>

          {/* Centered Search Box */}
          <div className="search-container">
            <input
              type="text"
              placeholder="Search guidelines by keywords..."
              value={searchTerm}
              onChange={handleSearchChange}
              className="search-input"
            />
          </div>

          {/* Guidelines List with animation */}
          <div className={`guidelines-list ${searchTerm.trim() ? "show" : ""}`}>
            {searchTerm.trim() ? (
              filteredGuidelines.length > 0 ? (
                filteredGuidelines.map((guideline, index) => (
                  <div key={index} className="guideline-card">
                    <p className="guideline-text">{guideline.text}</p>
                    <div className="guideline-meta">
                      <span className="guideline-class">{guideline.class}</span>
                      <span className="guideline-source">
                        {guideline.source}
                      </span>
                    </div>
                  </div>
                ))
              ) : (
                <p className="no-results">No guidelines found</p>
              )
            ) : null}
          </div>
        </div>

        <div className="mt-4">
          <Link to="/" className="home-button">
            Home
          </Link>
        </div>
      </div>
    </div>
  );
};

export default GuidelineAssistant;
