// CollapsibleTab.js
import React, { useState } from "react";
import "./App.css"; // Import your CSS for styling

const CollapsibleTab = ({ links }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleTab = () => {
    setIsOpen((prevState) => !prevState);
  };

  return (
    <div className="references-container">
      {isOpen && (
        <div className="references-content">
          <ul className="references-list">
            {links.map((link, index) => (
              <li key={index} className="reference-item">
                <a href={link.href} target="_blank" rel="noopener noreferrer">
                  {link.text}
                </a>
              </li>
            ))}
          </ul>
        </div>
      )}
      <button onClick={toggleTab} className="reference-button">
        {isOpen ? "Hide references" : "Show references"}
      </button>
    </div>
  );
};

export default CollapsibleTab;
