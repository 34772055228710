// src/Disclaimer.js

import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./App.css"; // Import custom styles
import Menu from "./Menu"; // Import the Menu component
import Footer from "./Footer";

const Disclaimer = () => {
  return (
    <div className="page_div">
      <div className="menu_div">
        <Menu />
      </div>

      <div className="content_div">
        <div>
          <h3>Disclaimer</h3>
        </div>
        <div>
          <p>
            {
              "The information provided on this website is intended for informational and educational purposes only and is not a substitute for professional medical advice, diagnosis, or treatment. While we try to keep the content accurate and up-to-date, we make no representations or warranties regarding the accuracy, completeness, or reliability of any information presented. Medical knowledge and standards of care are constantly evolving. Therefore, the information on this website may not reflect the most current research or medical practices. Always consult with a qualified healthcare professional or medical specialist for advice regarding any medical condition or treatment."
            }
          </p>
          <p>
            {
              "We disclaim any liability for any errors or omissions in the information provided and for any actions taken based on the content of this website. By using this website, you agree to hold us harmless from any claims or damages arising from the use of or reliance on the information provided."
            }
          </p>
          <p>
            {
              "If you have any concerns or need specific medical advice, please seek the guidance of a licensed healthcare professional."
            }
          </p>
        </div>

        <div className="mt-4">
          <Link to="/" className="home-button">
            Home
          </Link>
        </div>
      </div>
    </div>
  );
};
export default Disclaimer;
