// src/components/Header.js
import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import { FaBars } from "react-icons/fa";
import { Link } from "react-router-dom";
import "./App.css";

// Define the breakpoint consistently with CSS
const MOBILE_BREAKPOINT = 780;

// Component for the dropdown menu that will be rendered into a portal
const DropdownMenu = ({
  isOpen,
  handleLinkClick,
  isMobileView,
  dropdownRef,
}) => {
  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div
      ref={dropdownRef}
      className={`header__dropdown ${isMobileView ? "mobile" : "desktop"}`}
      style={{
        position: "fixed",
        top: "50px",
        right: "20px",
        zIndex: 9999,
      }}
    >
      <Link to="/cmrflow" className="nav-link" onClick={handleLinkClick}>
        CMR Flows
      </Link>
      <Link
        to="/chronotrop-index"
        className="nav-link"
        onClick={handleLinkClick}
      >
        Chronotropic Index
      </Link>
      <Link to="/ecv-calculator" className="nav-link" onClick={handleLinkClick}>
        ECV Calculator
      </Link>
      <Link to="/fickcalculator" className="nav-link" onClick={handleLinkClick}>
        Fick Calculator
      </Link>
      <Link
        to="/guideline-assistant"
        className="nav-link"
        onClick={handleLinkClick}
      >
        Guideline Assistant
      </Link>
      <Link to="/hr-calculator" className="nav-link" onClick={handleLinkClick}>
        HR Calculator
      </Link>
      <Link to="/ldl-apob" className="nav-link" onClick={handleLinkClick}>
        LDL-C, ApoB Comparator
      </Link>
      <Link to="/lge-risk" className="nav-link" onClick={handleLinkClick}>
        LGE Risk Estimator
      </Link>
      <Link to="/lvh-thresholds" className="nav-link" onClick={handleLinkClick}>
        LVH Thresholds
      </Link>
      <Link to="/metscalculator" className="nav-link" onClick={handleLinkClick}>
        METs Calculator
      </Link>
      <Link
        to="/prostheticvalves"
        className="nav-link"
        onClick={handleLinkClick}
      >
        Prosthetic Valves
      </Link>
      <Link to="/ra-pressure" className="nav-link" onClick={handleLinkClick}>
        RA Pressure Algorithm
      </Link>
      <Link to="/tmgradient" className="nav-link" onClick={handleLinkClick}>
        Transmitral Gradients
      </Link>
      <Link to="/disclaimer" className="nav-link" onClick={handleLinkClick}>
        Disclaimer
      </Link>
    </div>,
    document.body
  );
};

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const dropdownRef = useRef(null);
  const menuButtonRef = useRef(null);

  // Add event listener for window resize
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isOpen &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        menuButtonRef.current &&
        !menuButtonRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleLinkClick = () => {
    setIsOpen(false); // Close the menu when a link is clicked
  };

  // Determine if we're in mobile view
  const isMobileView = windowWidth <= MOBILE_BREAKPOINT;

  return (
    <header className="header">
      <div
        className="header__title"
        style={{ maxWidth: isMobileView ? "70%" : "auto", overflow: "hidden" }}
      >
        <Link
          to="/"
          className="header__link"
          onClick={handleLinkClick}
          style={{
            textDecoration: "none",
            display: "inline-block",
            maxWidth: "100%",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            fontSize: isMobileView ? "22px" : "26px",
          }}
        >
          Cardiology Reference
        </Link>
      </div>
      <div
        ref={menuButtonRef}
        className={`header__menu ${isOpen ? "active" : ""}`}
        onClick={toggleMenu}
      >
        <FaBars size={24} color="#fcf4f3" />
      </div>

      {/* Render the dropdown menu using a portal */}
      <DropdownMenu
        isOpen={isOpen}
        handleLinkClick={handleLinkClick}
        isMobileView={isMobileView}
        dropdownRef={dropdownRef}
      />
    </header>
  );
};

export default Header;
