import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./App.css";
import Menu from "./Menu";

const HRCalculator = () => {
  const [rrInterval, setRrInterval] = useState("");
  const [heartRate, setHeartRate] = useState("");
  const [result, setResult] = useState(null);
  const [resultType, setResultType] = useState(null);
  const [showMenu, setShowMenu] = useState(window.innerWidth > 768);

  useEffect(() => {
    const handleResize = () => {
      setShowMenu(window.innerWidth > 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Calculate heart rate when RR interval changes
  useEffect(() => {
    if (rrInterval && rrInterval > 0) {
      // Clear heart rate input when calculating from RR interval
      setHeartRate("");

      // Calculate HR from RR interval (ms)
      const calculatedHR = Math.round((60000 / rrInterval) * 10) / 10;
      setResult(calculatedHR);
      setResultType("hr");
    }
  }, [rrInterval]);

  // Calculate RR interval when heart rate changes
  useEffect(() => {
    if (heartRate && heartRate > 0) {
      // Clear RR interval input when calculating from heart rate
      setRrInterval("");

      // Calculate RR interval (ms) from HR
      const calculatedRR = Math.round(60000 / heartRate);
      setResult(calculatedRR);
      setResultType("rr");
    }
  }, [heartRate]);

  const handleRrIntervalChange = (e) => {
    const value = e.target.value;
    if (value === "" || value >= 0) {
      setRrInterval(value);
      if (value === "") {
        setResult(null);
      }
    }
  };

  const handleHeartRateChange = (e) => {
    const value = e.target.value;
    if (value === "" || value >= 0) {
      setHeartRate(value);
      if (value === "") {
        setResult(null);
      }
    }
  };

  return (
    <div className="page_div">
      {showMenu && (
        <div className="menu_div">
          <Menu />
        </div>
      )}
      <div className="content_div">
        <h1 className="title">HR Calculator</h1>

        <div className="calculator-container" style={{ maxWidth: "90%" }}>
          <div className="row mb-3">
            <div className="col-md-6">
              <label htmlFor="rrInterval" className="form-label">
                RR Interval/Cycle Length:
              </label>
              <input
                type="number"
                id="rrInterval"
                className="form-control"
                value={rrInterval}
                onChange={handleRrIntervalChange}
                placeholder="ms"
                min="0"
              />
            </div>

            <div className="col-md-6">
              <label htmlFor="heartRate" className="form-label">
                Heart Rate:
              </label>
              <input
                type="number"
                id="heartRate"
                className="form-control"
                value={heartRate}
                onChange={handleHeartRateChange}
                placeholder="bpm"
                min="0"
              />
            </div>
          </div>

          {result && (
            <div className="d-flex justify-content-center">
              <div className="resultcard">
                <h4 style={{ margin: 0, textAlign: "center" }}>
                  {resultType === "hr" ? "Heart Rate" : "Cycle Length"}
                </h4>
                <h5
                  style={{ margin: 0, textAlign: "center", fontWeight: "bold" }}
                >
                  {resultType === "hr" ? `${result} bpm` : `${result} ms`}
                </h5>
              </div>
            </div>
          )}
        </div>

        <div className="mt-4">
          <Link to="/" className="home-button">
            Home
          </Link>
        </div>
      </div>
    </div>
  );
};

export default HRCalculator;
