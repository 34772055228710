import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Menu from "./Menu";
import Footer from "./Footer";
import CollapsibleTab from "./CollapsibleTab";

const links = [
  {
    href: "https://www.elsevier.com/books/braunwalds-heart-disease/zipes/978-0-323-88395-0",
    text: "Braunwald's Heart Disease: A Textbook of Cardiovascular Medicine, 12th Edition",
  },
];

const ChronotropIndex = () => {
  const [age, setAge] = useState("");
  const [restingHR, setRestingHR] = useState("");
  const [maxHR, setMaxHR] = useState("");
  const [chronotropicIndex, setChronotropicIndex] = useState(null);
  const [showMenu, setShowMenu] = useState(window.innerWidth > 768);
  const [error, setError] = useState("");

  useEffect(() => {
    const handleResize = () => {
      setShowMenu(window.innerWidth > 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Add effect to calculate chronotropic index whenever inputs change
  useEffect(() => {
    if (age && restingHR && maxHR) {
      calculateChronotropicIndex();
    }
  }, [age, restingHR, maxHR]);

  const calculateChronotropicIndex = () => {
    // Clear any previous errors
    setError("");

    // Convert inputs to numbers
    const ageValue = parseFloat(age);
    const restingHRValue = parseFloat(restingHR);
    const maxHRValue = parseFloat(maxHR);

    // Validate inputs
    if (isNaN(ageValue) || isNaN(restingHRValue) || isNaN(maxHRValue)) {
      setChronotropicIndex(null);
      return;
    }

    if (ageValue <= 0 || ageValue >= 120) {
      setError("Please enter a valid age between 1 and 120.");
      setChronotropicIndex(null);
      return;
    }

    if (restingHRValue <= 20 || restingHRValue >= 200) {
      setError(
        "Please enter a valid resting heart rate between 20 and 200 bpm."
      );
      setChronotropicIndex(null);
      return;
    }

    if (maxHRValue <= restingHRValue) {
      setError("Maximum heart rate must be greater than resting heart rate.");
      setChronotropicIndex(null);
      return;
    }

    // Calculate chronotropic index using the formula
    const denominator = 220 - ageValue - restingHRValue;
    if (denominator <= 0) {
      setError("Invalid parameters for calculation. Please check your inputs.");
      setChronotropicIndex(null);
      return;
    }

    const index = ((maxHRValue - restingHRValue) / denominator) * 100;
    setChronotropicIndex(index.toFixed(1));
  };

  const handleAgeChange = (e) => {
    setAge(e.target.value);
  };

  const handleRestingHRChange = (e) => {
    setRestingHR(e.target.value);
  };

  const handleMaxHRChange = (e) => {
    setMaxHR(e.target.value);
  };

  return (
    <div className="page_div" data-component="chronotrop-index">
      {showMenu && (
        <div className="menu_div">
          <Menu />
        </div>
      )}

      <div className="content_div">
        <h1 className="title">Chronotropic Index</h1>

        <div className="calculator-container" style={{ maxWidth: "90%" }}>
          <div className="row mb-3">
            <div className="col-md-4">
              <label htmlFor="age" className="form-label">
                Age:
              </label>
              <input
                type="number"
                id="age"
                className="form-control"
                value={age}
                onChange={handleAgeChange}
                placeholder="years"
              />
            </div>
            <div className="col-md-4">
              <label htmlFor="restingHR" className="form-label">
                Resting Heart Rate:
              </label>
              <input
                type="number"
                id="restingHR"
                className="form-control"
                value={restingHR}
                onChange={handleRestingHRChange}
                placeholder="bpm"
              />
            </div>
            <div className="col-md-4">
              <label htmlFor="maxHR" className="form-label">
                Maximal Stress Heart Rate:
              </label>
              <input
                type="number"
                id="maxHR"
                className="form-control"
                value={maxHR}
                onChange={handleMaxHRChange}
                placeholder="bpm"
              />
            </div>
          </div>

          {error && (
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          )}

          {chronotropicIndex !== null && (
            <div
              className="mt-3"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div className="resultcard">
                <h4>Chronotropic Index</h4>
                <h3>
                  <strong>{chronotropicIndex}%</strong>
                </h3>
              </div>
            </div>
          )}

          <div className="mt-4">
            <Link to="/" className="home-button">
              Home
            </Link>
            <CollapsibleTab links={links} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChronotropIndex;
