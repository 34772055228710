import React, { useState, useCallback } from "react";
import ReactFlow, {
  MiniMap,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  addEdge,
} from "reactflow";
import "reactflow/dist/style.css";
import { Link } from "react-router-dom";
import Menu from "./Menu";
import "./App.css";
import Footer from "./Footer";
import CollapsibleTab from "./CollapsibleTab";

const links = [
  {
    href: "https://www.asecho.org/wordpress/wp-content/uploads/2015/02/2015_ASEguidelines_RAP.pdf",
    text: "Guidelines for the Echocardiographic Assessment of the Right Heart in Adults",
  },
];

const initialNodes = [
  {
    id: "1",
    type: "input",
    data: { label: "IVC ≤ 2.1 cm" },
    position: { x: 400, y: 0 },
    className: "light",
    style: {
      width: "120px",
      height: "120px",
      clipPath: "polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%)",
      backgroundColor: "#6db2d5",
      color: "white",
      fontSize: "14px",
      fontWeight: "bold",
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "0 20px",
    },
  },
  {
    id: "2",
    data: { label: "Collapsible > 50%" },
    position: { x: 250, y: 150 },
    className: "light",
  },
  {
    id: "3",
    data: { label: "Collapsible ≤ 50%" },
    position: { x: 550, y: 150 },
    className: "light",
  },
  {
    id: "4",
    type: "output",
    data: { label: "RAP = 3 mmHg" },
    position: { x: 250, y: 300 },
    className: "light",
  },
  {
    id: "5",
    type: "output",
    data: { label: "RAP = 8 mmHg" },
    position: { x: 550, y: 300 },
    className: "light",
  },
  {
    id: "6",
    type: "output",
    data: { label: "RAP = 15 mmHg" },
    position: { x: 400, y: 450 },
    className: "light",
  },
];

const initialEdges = [
  { id: "e1-2", source: "1", target: "2", label: "Yes" },
  { id: "e1-3", source: "1", target: "3", label: "No" },
  { id: "e2-4", source: "2", target: "4", label: "Direct" },
  { id: "e3-5", source: "3", target: "5", label: "Direct" },
  { id: "e1-6", source: "1", target: "6", label: "Direct" },
];

const RaPressure = () => {
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const [showMenu, setShowMenu] = useState(window.innerWidth > 768);

  const onConnect = useCallback(
    (params) => setEdges((eds) => addEdge(params, eds)),
    [setEdges]
  );

  React.useEffect(() => {
    const handleResize = () => {
      setShowMenu(window.innerWidth > 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="page_div" data-component="ra-pressure">
      {showMenu && (
        <div className="menu_div">
          <Menu />
        </div>
      )}

      <div className="content_div">
        <h1 className="title">Right Atrial Pressure Estimation Algorithm</h1>
        <h3
          style={{
            textAlign: "center",
            marginTop: "-20px",
            marginBottom: "20px",
            color: "#939393",
          }}
        >
          (under construction)
        </h3>

        <div style={{ width: "100%", height: "600px", margin: "20px 0" }}>
          <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            fitView
          >
            <Controls />
            <MiniMap />
            <Background variant="dots" gap={12} size={1} />
          </ReactFlow>
        </div>

        <div className="mt-4">
          <Link to="/" className="home-button">
            Home
          </Link>
          <CollapsibleTab links={links} />
        </div>
      </div>
    </div>
  );
};

export default RaPressure;
