import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./App.css";
import Menu from "./Menu";
import Footer from "./Footer";
import ReactApexChart from "react-apexcharts";
import CollapsibleTab from "./CollapsibleTab";

const links = [
  {
    href: "https://pubmed.ncbi.nlm.nih.gov/39515615/",
    text: "Late gadolinium enhancement on cardiac MRI: A systematic review and meta-analysis of prognosis across cardiomyopathies ",
  },
];

const LgeRiskEstimator = () => {
  const [showMenu, setShowMenu] = useState(window.innerWidth > 768);
  const [lgeValue, setLgeValue] = useState("");
  const [baselineRisk, setBaselineRisk] = useState("");
  const [icdThreshold, setIcdThreshold] = useState("");
  const [relativeRisk, setRelativeRisk] = useState(null);
  const [series, setSeries] = useState([]);
  const [userPoint, setUserPoint] = useState([]);
  const [maxYValue, setMaxYValue] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      setShowMenu(window.innerWidth > 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Calculate relative risk
  const calculateRisk = (lge) => {
    return Math.exp(Math.log(1.0367) * lge);
  };

  // Calculate upper and lower bounds
  const calculateUpperBound = (lge) => {
    return Math.exp(Math.log(1.0545) * lge);
  };

  const calculateLowerBound = (lge) => {
    return Math.exp(Math.log(1.0193) * lge);
  };

  // Calculate LGE at ICD threshold
  const calculateLgeAtThreshold = (threshold, baselineRisk) => {
    // If using absolute risk, convert threshold to relative risk
    const relativeThreshold = baselineRisk
      ? threshold / parseFloat(baselineRisk)
      : threshold;
    // Solve for x: exp(ln(1.0367) * x) = relativeThreshold
    // x = ln(relativeThreshold) / ln(1.0367)
    return Math.log(relativeThreshold) / Math.log(1.0367);
  };

  // Generate points for the curve
  useEffect(() => {
    const points = [];
    const upperPoints = [];
    const lowerPoints = [];
    const thresholdPoints = [];
    const thresholdIntersectionPoint = [];
    let maxY = 0;

    for (let x = 0; x <= 30; x += 0.1) {
      const y = calculateRisk(x);
      const upperY = calculateUpperBound(x);
      const lowerY = calculateLowerBound(x);
      // If baseline risk is entered, scale the maximum Y value
      const scaledUpperY = baselineRisk
        ? upperY * parseFloat(baselineRisk)
        : upperY;
      maxY = Math.max(maxY, scaledUpperY);

      if (icdThreshold && baselineRisk) {
        thresholdPoints.push({
          x: x,
          y: parseFloat(icdThreshold),
        });
      }

      points.push({
        x: x,
        y: baselineRisk ? y * parseFloat(baselineRisk) : y,
      });
      upperPoints.push({
        x: x,
        y: baselineRisk ? upperY * parseFloat(baselineRisk) : upperY,
      });
      lowerPoints.push({
        x: x,
        y: baselineRisk ? lowerY * parseFloat(baselineRisk) : lowerY,
      });
    }

    // Add threshold intersection point if we have both ICD threshold and baseline risk
    if (icdThreshold && baselineRisk) {
      const intersectionLGE = calculateLgeAtThreshold(
        parseFloat(icdThreshold),
        parseFloat(baselineRisk)
      );
      thresholdIntersectionPoint.push({
        x: intersectionLGE,
        y: parseFloat(icdThreshold),
      });
    }

    setMaxYValue(maxY + 0.5);
    setSeries([
      {
        name: "Upper Bound",
        type: "line",
        data: upperPoints,
      },
      {
        name: "Relative Risk",
        data: points,
      },
      {
        name: "Lower Bound",
        type: "line",
        data: lowerPoints,
      },
      {
        name: "ICD Threshold",
        type: "line",
        data: icdThreshold && baselineRisk ? thresholdPoints : [],
      },
      {
        name: "Threshold Intersection",
        type: "scatter",
        data: icdThreshold && baselineRisk ? thresholdIntersectionPoint : [],
      },
    ]);
  }, [baselineRisk, icdThreshold]);

  // Update user point when LGE value changes
  useEffect(() => {
    if (lgeValue && !isNaN(lgeValue)) {
      const risk = calculateRisk(parseFloat(lgeValue));
      setRelativeRisk(risk);
      setUserPoint([
        {
          name: "Risk Estimate",
          type: "scatter",
          data: [
            {
              x: parseFloat(lgeValue),
              y: baselineRisk ? risk * parseFloat(baselineRisk) : risk,
            },
          ],
        },
      ]);
    } else {
      setUserPoint([]);
      setRelativeRisk(null);
    }
  }, [lgeValue, baselineRisk]);

  const chartOptions = {
    chart: {
      type: "line",
      height: 400,
      toolbar: {
        show: false,
      },
      fontFamily: "Poppins, sans-serif",
    },
    xaxis: {
      title: {
        text: "LGE (% LV myocardium)",
        style: {
          fontFamily: "Poppins, sans-serif",
          fontWeight: 400,
        },
      },
      min: 0,
      max: 30,
      decimalsInFloat: 1,
      tickAmount: 6,
      style: {
        fontFamily: "Poppins, sans-serif",
      },
    },
    yaxis: {
      title: {
        text: baselineRisk ? "Absolute Risk (%/year)" : "Relative Risk",
        style: {
          fontFamily: "Poppins, sans-serif",
          fontWeight: 400,
        },
      },
      min: baselineRisk ? parseFloat(baselineRisk) : 1,
      max: maxYValue,
      decimalsInFloat: 1,
      style: {
        fontFamily: "Poppins, sans-serif",
      },
      labels: {
        formatter: function (value) {
          if (baselineRisk) {
            return value.toFixed(1);
          }
          return value.toFixed(1);
        },
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
      style: {
        fontFamily: "Poppins, sans-serif",
      },
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        let content = "";
        if (seriesIndex === 1) {
          // Main line
          const x = w.globals.seriesX[seriesIndex][dataPointIndex];
          const pointEstimate = calculateRisk(x);
          const lowerBound = calculateLowerBound(x);
          const upperBound = calculateUpperBound(x);
          content = `${pointEstimate.toFixed(1)}x (${lowerBound.toFixed(
            1
          )}-${upperBound.toFixed(1)})`;
        } else if (seriesIndex === 2) {
          // User point
          const value = series[seriesIndex][dataPointIndex];
          content = `${value.toFixed(1)}x risk`;
        }
        return '<div class="custom-tooltip">' + content + "</div>";
      },
    },
    stroke: {
      curve: "smooth",
      width: [1, 3, 1, 2, 0, 0], // [upper, main, lower, threshold, intersection, user point]
      dashArray: [0, 0, 0, 0, 0, 0],
    },
    markers: {
      size: [0, 0, 0, 0, 10, 10], // [upper, main, lower, threshold, intersection, user point]
    },
    colors: ["#666666", "#2E93fA", "#666666", "#FF0000", "#FF0000", "#2E93fA"], // [upper, main, lower, threshold, intersection, user point]
    legend: {
      fontFamily: "Poppins, sans-serif",
      fontWeight: 400,
    },
    annotations: {
      points: [
        // Your Risk point annotation
        ...(lgeValue && !isNaN(lgeValue)
          ? [
              {
                x: parseFloat(lgeValue),
                y: baselineRisk
                  ? calculateRisk(parseFloat(lgeValue)) *
                    parseFloat(baselineRisk)
                  : calculateRisk(parseFloat(lgeValue)),
                marker: {
                  size: 0,
                },
                label: {
                  borderColor: "#fff",
                  offsetY: -15,
                  style: {
                    color: "#000",
                    background: "#fff",
                    padding: 5,
                    fontSize: "12px",
                  },
                  text: "Risk Estimate",
                },
              },
            ]
          : []),
        // Threshold intersection point annotation
        ...(icdThreshold && baselineRisk
          ? [
              {
                x: calculateLgeAtThreshold(
                  parseFloat(icdThreshold),
                  parseFloat(baselineRisk)
                ),
                y: parseFloat(icdThreshold),
                marker: {
                  size: 0,
                },
                label: {
                  borderColor: "#fff",
                  offsetY: -15,
                  style: {
                    color: "#000",
                    background: "#fff",
                    padding: 5,
                    fontSize: "12px",
                  },
                  text: "LGE at ICD Threshold",
                },
              },
            ]
          : []),
      ],
    },
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (value === "" || (parseFloat(value) >= 0 && parseFloat(value) <= 30)) {
      setLgeValue(value);
    }
  };

  return (
    <div className="page_div" data-component="lge-risk">
      {showMenu && (
        <div className="menu_div">
          <Menu />
        </div>
      )}

      <div className="content_div">
        <h1 className="title">LGE Risk Estimator</h1>

        <div className="calculator-container" style={{ maxWidth: "90%" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "20px",
              marginBottom: "20px",
              width: "100%",
              maxWidth: "900px",
              margin: "0 auto 20px auto",
              flexDirection: window.innerWidth <= 768 ? "column" : "row",
              padding: "0 15px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                flex: 1,
                flexDirection: window.innerWidth <= 768 ? "column" : "row",
              }}
            >
              <label
                htmlFor="lgeInput"
                className="form-label"
                style={{
                  margin: 0,
                  lineHeight: "1.2",
                  fontSize: "clamp(0.8rem, 2vw, 1rem)",
                  width: window.innerWidth <= 768 ? "100%" : "auto",
                  textAlign: window.innerWidth <= 768 ? "left" : "right",
                }}
              >
                Enter LGE
              </label>
              <input
                type="number"
                id="lgeInput"
                className="form-control"
                value={lgeValue}
                onChange={handleInputChange}
                min="0"
                max="30"
                step="0.1"
                placeholder="% LV myocardium"
                style={{
                  margin: 0,
                  flex: 1,
                  width: window.innerWidth <= 768 ? "100%" : "auto",
                  fontSize: "clamp(0.8rem, 2vw, 1rem)",
                }}
              />
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                flex: 1,
                flexDirection: window.innerWidth <= 768 ? "column" : "row",
              }}
            >
              <label
                htmlFor="baselineRisk"
                className="form-label"
                style={{
                  margin: 0,
                  lineHeight: "1.2",
                  fontSize: "clamp(0.8rem, 2vw, 1rem)",
                  width: window.innerWidth <= 768 ? "100%" : "auto",
                  textAlign: window.innerWidth <= 768 ? "left" : "right",
                }}
              >
                Baseline risk
              </label>
              <input
                type="number"
                id="baselineRisk"
                className="form-control"
                value={baselineRisk}
                onChange={(e) => setBaselineRisk(e.target.value)}
                min="0"
                step="0.1"
                placeholder="Annual risk (optional)"
                style={{
                  margin: 0,
                  flex: 1,
                  width: window.innerWidth <= 768 ? "100%" : "auto",
                  fontSize: "clamp(0.8rem, 2vw, 1rem)",
                }}
              />
            </div>

            {baselineRisk && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  flex: 1,
                  flexDirection: window.innerWidth <= 768 ? "column" : "row",
                }}
              >
                <label
                  htmlFor="icdThreshold"
                  className="form-label"
                  style={{
                    margin: 0,
                    lineHeight: "1.2",
                    fontSize: "clamp(0.8rem, 2vw, 1rem)",
                    width: window.innerWidth <= 768 ? "100%" : "auto",
                    textAlign: window.innerWidth <= 768 ? "left" : "right",
                  }}
                >
                  ICD Threshold
                </label>
                <input
                  type="number"
                  id="icdThreshold"
                  className="form-control"
                  value={icdThreshold}
                  onChange={(e) => setIcdThreshold(e.target.value)}
                  min="0"
                  step="0.1"
                  placeholder="Absolute annual risk"
                  style={{
                    margin: 0,
                    flex: 1,
                    width: window.innerWidth <= 768 ? "100%" : "auto",
                    fontSize: "clamp(0.8rem, 2vw, 1rem)",
                  }}
                />
              </div>
            )}
          </div>

          {relativeRisk !== null && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "5px",
                width: "100%",
                maxWidth: "100%",
                padding: "5px 0",
              }}
            >
              <div
                className="results-div-main mb-4"
                style={{
                  flex: 1,
                  minWidth: 0,
                  maxWidth: "33%",
                  padding: "5px",
                }}
              >
                <h4
                  style={{
                    fontSize: "clamp(0.7rem, 1.2vw, 1rem)",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    margin: "2px 0",
                    textAlign: "center",
                    wordBreak: "break-word",
                  }}
                >
                  Relative Risk
                </h4>
                <p
                  className="h3"
                  style={{
                    fontSize: "clamp(0.9rem, 1.5vw, 1.2rem)",
                    margin: "2px 0",
                    textAlign: "center",
                    wordBreak: "break-word",
                  }}
                >
                  {relativeRisk.toFixed(1)}x
                </p>
                <p
                  style={{
                    fontSize: "clamp(0.5rem, 1vw, 0.7rem)",
                    color: "#666",
                    margin: "2px 0",
                    textAlign: "center",
                    wordBreak: "break-word",
                  }}
                >
                  ({calculateLowerBound(parseFloat(lgeValue)).toFixed(1)}-
                  {calculateUpperBound(parseFloat(lgeValue)).toFixed(1)})
                </p>
              </div>
              {baselineRisk && (
                <div
                  className="results-div-main mb-4"
                  style={{
                    flex: 1,
                    minWidth: 0,
                    maxWidth: "33%",
                    padding: "5px",
                  }}
                >
                  <h4
                    style={{
                      fontSize: "clamp(0.7rem, 1.2vw, 1rem)",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      margin: "2px 0",
                      textAlign: "center",
                      wordBreak: "break-word",
                    }}
                  >
                    Absolute Risk
                  </h4>
                  <p
                    className="h3"
                    style={{
                      fontSize: "clamp(0.9rem, 1.5vw, 1.2rem)",
                      margin: "2px 0",
                      textAlign: "center",
                      wordBreak: "break-word",
                    }}
                  >
                    {(relativeRisk * parseFloat(baselineRisk)).toFixed(1)}%/year
                  </p>
                  <p
                    style={{
                      fontSize: "clamp(0.5rem, 1vw, 0.7rem)",
                      color: "#666",
                      margin: "2px 0",
                      textAlign: "center",
                      wordBreak: "break-word",
                    }}
                  >
                    (
                    {(
                      calculateLowerBound(parseFloat(lgeValue)) *
                      parseFloat(baselineRisk)
                    ).toFixed(1)}
                    -
                    {(
                      calculateUpperBound(parseFloat(lgeValue)) *
                      parseFloat(baselineRisk)
                    ).toFixed(1)}
                    )
                  </p>
                </div>
              )}
              {icdThreshold && (
                <div
                  className="results-div-main mb-4"
                  style={{
                    flex: 1,
                    minWidth: 0,
                    maxWidth: "33%",
                    padding: "5px",
                  }}
                >
                  <h4
                    style={{
                      fontSize: "clamp(0.7rem, 1.2vw, 1rem)",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      margin: "2px 0",
                      textAlign: "center",
                      wordBreak: "break-word",
                    }}
                  >
                    LGE at ICD Threshold
                  </h4>
                  <p
                    className="h3"
                    style={{
                      fontSize: "clamp(0.9rem, 1.5vw, 1.2rem)",
                      margin: "2px 0",
                      textAlign: "center",
                      wordBreak: "break-word",
                    }}
                  >
                    {calculateLgeAtThreshold(
                      parseFloat(icdThreshold),
                      parseFloat(baselineRisk)
                    ).toFixed(1)}
                    %
                  </p>
                </div>
              )}
            </div>
          )}

          <div className="chart-container">
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                textAlign: "center",
                fontFamily: "Poppins, sans-serif",
                fontSize: "16px",
                fontWeight: 400,
                marginBottom: "0px",
                marginTop: "25px",
              }}
            >
              Risk of Life-Threatening Ventricular Arrhythmias
            </div>
            <ReactApexChart
              options={chartOptions}
              series={[...series, ...userPoint]}
              type="line"
              height={400}
            />
          </div>
        </div>

        <div className="mt-4">
          <Link to="/" className="home-button">
            Home
          </Link>
          <CollapsibleTab links={links} />
        </div>
      </div>
    </div>
  );
};

export default LgeRiskEstimator;
